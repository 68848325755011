import React from "react";

const ExploreBtn = ({ button, filter }) => {
  return (
    <>
      {/* <div className="sort">
        <div className="sort_s">
          <div className="sort_data sort_ds">SORT BY</div>
          <div width="180" height="190" className="sort_da">
            <div className="sort_dab">
              <div className="sort_data sort_ds">HOT</div>
              <svg
                viewBox="0 0 24 24"
                color="text"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
                class="sc-bdfBwQ lkvAzg"
              >
                <path d="M8.71005 11.71L11.3001 14.3C11.6901 14.69 12.3201 14.69 12.7101 14.3L15.3001 11.71C15.9301 11.08 15.4801 10 14.5901 10H9.41005C8.52005 10 8.08005 11.08 8.71005 11.71Z"></path>
              </svg>
            </div>
          </div>
        </div>

        {/* Search }
        <div className="search_b">
          <div className="sort_data sort_ds">SEARCH</div>
          <div className="search_ba">
            <div className="search_bb">
              <input
                placeholder="Search fusi"
                scale="md"
                class="sc-bBXqnf sc-JooDp dGmcca huvkpK"
                value=""
              ></input>
            </div>
          </div>
        </div>
      </div>*/}
      <div className="tltl" style={{ textAlign: "center" }}>
        <h1
          className="p_desc"
          style={{
            marginBottom: "50px",
            fontSize: "60px",
          }}
        >
          EXPLORE NFTs
        </h1>
      </div>

      <div className="filter_button">
        {button.map((cat, i) => {
          return (
            <button
              type="button"
              onClick={() => filter(cat)}
              className="filter_btn"
            >
              {cat}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default ExploreBtn;
