import React, { useState, useContext, useEffect } from "react";
import Search from "./Search";
// import ModalBox from "./ModalBox";
import { AiFillInfoCircle } from "react-icons/ai";
import { Switch, Tabs, InputNumber, Input, message } from "antd";
import "antd/dist/antd.css";
import cardImg from "../images/image-placeholder-square.png";
import { Web3Context } from "../Context/Web3context";
import { NFTSearchData } from "../Context/NFTSearchData";
// import NumSlider from "./NumSlider";
//import { ToastContainer, toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import { Ierc721, coreAbi, Ierc20, FTAbi } from "../Contract/abi/abi";
import { NFTMeta ,CoreV0} from "../Contract/constant";
import { SRLWrapper } from "simple-react-lightbox";
import CreateModal from "./CreateModal";
import { FaExchangeAlt } from "react-icons/fa";
import { IoPulseSharp } from "react-icons/io5";
import { ImArrowUpRight2 } from "react-icons/im";
import {pingSold,fetchNData} from "../FetchApis/FetchNFT";
import axios from "axios";
import Hint from "./Hint";
import { Redirect } from "react-router";
import { TwitterShareButton, TwitterIcon } from "react-share";
import { AiOutlineTwitter } from "react-icons/ai";
import ShareOptn from "./ShareOptn";

const { TabPane } = Tabs;

const AppV0 = (props) => {
  const state = useContext(Web3Context);
  const [modalShow, setModalShow] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [recData, setRecData] = useState();
  const [tokenURI, setTokenURI] = useState({ name: "", desc: "", uri: null });
  const [toggle, setToggle] = useState(false);
  const [curveData, setCurveData] = useState({});
  const [mediaType, setType] = useState(true);
  const [nftPrice, setNftPrice] = useState({
    buyOut: 0,
    ftReq: 0,
    poolFlag: false,
  });
  const [reSelFT, setReSelFT] = useState({ seller: "" });
  const [buyNFT, setBuyNFT] = useState("00");
  const [buyFT, setBuyFT] = useState("00");
  const [valueFT, setFTValue] = useState();
  const [valueNFT, setNFTValue] = useState();
  const [valueMaxETHFT, setMaxETHFT] = useState(0);
  const [isListed, setListed] = useState(true);
  const [metaData, setMetaData] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [share, setShare] = useState(false);
  const [ndata,setNData]=useState();

  //const notify = () => toast.error("NFT isn't listed on fusible yet!");
  const toggler = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  //console.log(query.get('tid'));
  const reset = () => {
    setNftPrice({ buyOut: 0, ftReq: 0, poolFlag: false });
    setType(true);
    setBuyFT("00");
    setBuyNFT("00");
    setReSelFT({ seller: "" });
    // setTokenURI({ name: "", desc: "", uri: cardImg });
    setMaxETHFT();
    setCurveData({});
  };
  /*
  const checkIfUserhasEnoughFT = (e) => {
    const core = new state.web3.eth.Contract(Ierc20, reSelFT.ftToken);
    core.methods
      .balanceOf(state.address)
      .call({ from: state.address })
      .then((r) => {
        let user = parseFloat(state.web3.utils.fromWei(r.toString(), "ether"));
        e = parseFloat(state.web3.utils.fromWei(e.toString(), "ether"));
        if (user >= e) {
          if (e === 0) {
            if (recData.receipt) {
              const core = new state.web3.eth.Contract(coreAbi, CoreV0);
              core.methods
                .exit(recData.receipt)
                .send({ from: state.address, value: valueNFT })
                .then((r) => {
                  console.log(r);
                });
            }
          } else {
            core.methods
              .approve(CoreV0, r)
              .send({ from: state.address })
              .then((r) => {
                if (recData.receipt) {
                  const core = new state.web3.eth.Contract(coreAbi, CoreV0);
                  core.methods
                    .exit(recData.receipt)
                    .send({ from: state.address, value: valueNFT })
                    .then((r) => {
                      console.log(r);
                    });
                }
              });
          }
          console.log(user, e);
        } else {
          alert("You need minimum of:\t " + e + " FT tokens to buy the nft.");
        }

        //console.log(typeof r,typeof e);
      });
  };*/
  //const nftBUY(e)
  const buy = () => {
    if (
      state !== undefined &&
      recData !== undefined &&
      valueNFT !== undefined
    ) {
      //checkIfUserhasEnoughFT(nftPrice.ftReq);
      let a = parseFloat(state.web3.utils.fromWei(valueNFT, "ether"));
      let b = parseFloat(state.web3.utils.fromWei(state.balance, "ether"));
      console.log("whats going on", state.balance, valueNFT, a, b, a > b);
      console.log(recData.receipt);
      if (recData.receipt !== undefined && b > a) {
        const core = new state.web3.eth.Contract(FTAbi, recData.ftToken);
        core.methods
          .exit(recData.receipt)
          .send({ from: state.address, value: valueNFT })
          .then((r) => {
            console.log(r);
            message.success("TXN Successful! You bought the NFT");
            reset();
            //<Redirect to='/profile/'
            // refresh();
            //let result =r.events.NFTListed.returnValues;
 
            let obj={_id: recData.ftToken,
            key: recData.n+'_'+recData.t,
            blockNum: r.blockNumber,
            nft:recData.n,
            tknId:parseInt(recData.t),
            FT:recData.ftToken,
            soldAt:parseFloat(state.web3.utils.fromWei(valueNFT, "ether")).toFixed(3),
            buyer:state.address}

            pingSold(obj).then(r=>{
              console.log(r);
            })


          })
          .catch((e) => {
            message.error("Buy: Txn Failed!");
          });
      }
    }
  };
  const buyFTs = () => {
    if (state !== undefined && recData !== undefined && valueFT !== undefined) {
      if (recData.receipt) {
        const core = new state.web3.eth.Contract(FTAbi, recData.ftToken);
        core.methods
          .buyMintedTokens(recData.receipt)
          .send({ from: state.address, value: valueFT })
          .then((r) => {
            console.log(r);
            message.success("TXN Successful! You bought the FT tokens");
            refresh();
          })
          .catch((e) => {
            message.error("Buy FT: Txn Failed");
          });
      }
    }
  };
  /* Out scope*/
  const setURI = (n, t) => {
    const ERC721 = new state.web3.eth.Contract(Ierc721, n);
    ERC721.methods
      .tokenURI(t)
      .call({ from: state.address })
      .then((result) => {
        getImage(result,n);

        //parseURL(result);
      })
      .catch((err) => {
        console.log(n, t);
        setListed(false);

        setTokenURI({ name: "", desc: "", uri: cardImg });
        reset();
      });
    ERC721.methods
      .ownerOf(t)
      .call({ from: state.address })
      .then((r) => {
        if (r === state.address) setIsOwner(true);
      });
  };
  const getReceiptData = (r, core) => {
    core.methods
      .getReceiptData(r)
      .call({ from: state.address })
      .then((result) => {
        const rSFT = {
          seller: result.seller,
        };
        // ftToken: result.mintedERC20Addr,
        //alert(rSFT);
        //console.log(result.mintedERC20Addr);
        getCurveData(recData.ftToken, core);
        setReSelFT(rSFT);
      })
      .catch((e) => {
        message.error("Cannot get the NFT data");
      });
  };
  const computeMaxETH = (ft, c) => {
    const ierc20 = new state.web3.eth.Contract(Ierc20, ft);
    ierc20.methods
      .balanceOf(recData.ftToken)
      .call({ from: state.address })
      .then((dBal) => {
        dBal = state.web3.utils.fromWei(dBal.toString(), "ether");

        let tSupply = state.web3.utils.fromWei(c.tSupply.toString(), "ether");
        let pBal = state.web3.utils.fromWei(c.pBal.toString(), "ether");
        let _maxETHForFT =
          (Math.pow((dBal / tSupply) + 1, (pBal/ (c.rR / 1000000)))) - pBal;
        setMaxETHFT(_maxETHForFT);
        console.log(dBal, pBal, tSupply, _maxETHForFT, c.rR / 1000000);
        c["dBalDecimal"] = parseFloat(dBal).toFixed(2);
        c["tSupplyDecimal"] = parseFloat(tSupply).toFixed(2);
        setCurveData(c);
      });
  };
  const getCurveData = (ft, core) => {
    core.methods
      .getCurve(ft)
      .call({ from: state.address })
      .then((result) => {
        const curve = {
          pBal: result.poolBalanceReserve,
          rR: result.rRatio,
          lPrice: result.lastPrice,
          tSupply: result.totalSupply,
          fPrice: result.floorPrice,
        };
        computeMaxETH(ft, curve);
        //console.log(curve);
      });
    core.methods
      .getRedeemingValues(ft)
      .call({ from: state.address })
      .then((result) => {
        //console.log(result);
        const redeem = {
          buyOut: result.ethValue,
          ftReq: result.tokens,
          poolFlag: result.xyFlag,
        };
        //alert(JSON.stringify(result));
        setNftPrice(redeem);

        //updateNFTData();
      });
  };
  function parseURL(uri) {
    var url = document.createElement("a");
    url.href = uri;
    if (url.protocol === "ipfs:")
      uri = uri.replace("ipfs://", "https://ipfs.io/ipfs/");

    return uri;
  }
  const writeMeta=(result)=>{
    console.log(result);
    setMetaData({ result });
    
    if (result["mimetype"] !== undefined) {
      result["mimetype"].match("mp4") || result["mimetype"].match("quicktime")
        ? setType(false)
        : setType(true);
      //  console.log(result["mimetype"]);
    }else if(result["attributes"] !== undefined){
      if(result["attributes"].content_type)
      result["attributes"].content_type.match("mp4") || result["attributes"].content_type.match("quicktime")
      ? setType(false)
      : setType(true);
    } 

    var u = parseURL(result["image"]);
    //setType(result["mimetype"].match("image"));
    setTokenURI({
      name: result["name"],
      desc: result["description"],
      uri: u,
    });
  }
  function getImage(url,n) {
    // url.replace('arweave','sdootys6huhhkhcwzp5tgsobvdapunays3r7irrkcaqagxvshwzq.arweave');
    url = { method: "GET", url: parseURL(url) };
    axios(url).then((result) => {
      
      //alert(JSON.stringify(result.data));
      result = NFTMeta[n]? NFTMeta[n].mould(result.data):result.data;
      writeMeta(result);

    }).catch(e=>console.log(e));

    //return img;
  }
  const refresh = () => {
    if (state !== undefined && recData !== undefined) {
      const core = new state.web3.eth.Contract(FTAbi, recData.ftToken);
      getCurveData(recData.ftToken, core);
    }
  };

  useEffect(() => {
    //alert("Component User Count Updated...");
    //console.log(recData);
    if (state !== undefined) {
      //console.log(nftData);

      /**Call the implementations */
      if (recData !== undefined) {
        const core = new state.web3.eth.Contract(FTAbi, recData.ftToken);
        if (recData.receipt) {
          setListed(true);
          getReceiptData(recData.receipt, core);
          setURI(recData.n, recData.t);
        } else {
          setURI(recData.n, recData.t);
          setListed(false);
          //notify();
          message.error("NFT isn't listed on fusible yet!");
          reset();
          //        setReSelFT();
          //        setTokenURI(cardImg);
          //        setCurveData();
          //        setNftPrice();
        }
      }
    }else{
      if(props.match.params.nft && props.match.params.id)
      fetchNData(props.match.params.nft+'_'+props.match.params.id).then(r=>{
        setNData(JSON.parse(r.data));
        //console.log(JSON.parse(r.data));
      })
    }
  }, [recData]);

  useEffect(() => {
    if (state !== undefined)
      setBuyNFT(
        parseFloat(
          state.web3.utils.fromWei(nftPrice.buyOut.toString(), "ether")
        ).toFixed(2)
      );

    setNFTValue(nftPrice.buyOut);
  }, [nftPrice]);

  useEffect(() => {
    if (
      state !== undefined &&
      recData !== undefined &&
      sliderValue !== undefined
    ) {
      if(sliderValue === null) return;
      let numStr = sliderValue.toString();

      const core = new state.web3.eth.Contract(FTAbi, recData.ftToken);
      //let numStr = sliderValue.toString();
      //let ethValue = new state.web3.utils.toBN(sliderValue);
      //console.log(ethValue);
      let ethValue = state.web3.utils.toWei(numStr, "ether");

      //ethValue = new state.web3.utils.toBN(ethValue);
      console.log(ethValue);
      //setFTValue(ethValue);

      core.methods
        .computeFTperETH(
          curveData.tSupply,
          curveData.pBal,
          curveData.rR,
          ethValue
        )
        .call({ from: state.address })
        .then((result) => {
          console.log(result._amtFT);
          setBuyFT(
            state.web3.utils
              .fromWei(result._amtFT.toString(), "ether")
              .substring(0, 4)
          );
          setFTValue(ethValue);
          //updateNFTData();
        }).catch(e=>{console.log(e,'<===')});
      //console.log(sliderValue);

      //setBuyFT()
    }
  }, [sliderValue]);

  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showCaption: false,
      showThumbnails: false,
    },
  };

  return (
    <>
      {state ? (
        <div className="container">
          {/*<ToastContainer position="top-center" />*/}
          <div className="row" style={{ marginTop: "50px" }}>
            <div className="column">
              {/* <div>
                <div
                  className="ant-row ant-row-space-between create-bottom-row"
                  style={{
                    rowGap: "0px",
                    border: "2px solid #3498db",
                    marginBottom: "10px",
                    borderRadius: "12px",
                    padding: "10px",
                  }}
                >
                  <div class="ant-col-16"></div>
                  <div> </div>
                </div>
              </div> */}
              <div className="card">
                <div className="card-parts">
                  <div className="image-fusi card-fusii">
                    <SRLWrapper options={options}>
                      {mediaType ? (
                        <img
                          src={tokenURI.uri ? tokenURI.uri : cardImg}
                          alt={metaData?metaData.result["name"]:'fusible'}
                          className="image-fusi"
                        />
                      ) : (
                        <>
                          <video
                            className="image-fusi"
                            src={tokenURI.uri}
                            preload={"auto"}
                            autoPlay={true}
                            loop={true}
                            playsInline=""
                            webkit-playsinline=""
                            style={{ width: "100%", height: "100%" }}
                            muted
                            controls
                            
                          ></video>
                        </>
                      )}
                    </SRLWrapper>
                  </div>
                  <div className="bottom-desc">
                    {/*isListed ? (
                      <>
                        <div className="chip ">
                          <div className="row">
                            <div className="col">
                              <p
                                className="left-corner"
                                style={{
                                  color: "#fff",
                                  fontSize: "11px",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                NAME: {tokenURI.name}
                              </p>
                            </div>
                            <div
                              className="col"
                              style={{
                                color: "#fff",
                                fontSize: "9px",
                                textAlign: "right",
                              }}
                            >
                              {/*curveData.dBalDecimal ? (
                                <p>
                                  {" "}
                                  Avail FT :{" "}
                                  {curveData.dBalDecimal +
                                    "/" +
                                    curveData.tSupplyDecimal}{" "}
                                </p>
                              ) : (
                                ""
                              )}

                              {/* <p className="right-corner">Dynamic Pricing Stats</p> 
                            </div>
                          </div>
                          {/**
                  <div className="row">
                  <p  style={{ color: "#fff", fontSize: "9px", textOverflow: "ellipsis"}} >Desc: {tokenURI.desc}</p>
                    </div> 
                        </div>

                        <div></div>
                      </>
                    ) : (
                      <></>
                    )*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <NFTSearchData.Provider value={(state, setRecData)}>
                <Search nft={props.match.params.nft} tknId={props.match.params.id} CoreV0={props.match.params.core?props.match.params.core:CoreV0} />
              </NFTSearchData.Provider>
              {/* Desciption */}
              <div className="description">
                {metaData ? (
                  <div className="desc-txts">
                                     <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>
                        <b class="p_desc">
                          {metaData.result["name"]}
                        </b>
                      </h5>
                      {/*
                      <div className="ppb2">
                          <div className="pbt2">
                            <button
                              onClick={() => setShare(!share)}
                              type="button"
                              className="cpb pbt3 pbt4"
                            >
                              <svg
                                viewBox="0 0 15 16"
                                fill="none"
                                width="13.200000000000001"
                                height="13.200000000000001"
                                xlmns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.7086 5.2928L7.00146 0.585693L2.29436 5.2928C1.90383 5.68332 1.90383 6.31649 2.29436 6.70701C2.68488 7.09754 3.31805 7.09754 3.70857 6.70701L6.00146 4.41412V11C6.00146 11.5523 6.44918 12 7.00146 12C7.55375 12 8.00146 11.5523 8.00146 11V4.41412L10.2944 6.70701C10.6849 7.09754 11.318 7.09754 11.7086 6.70701C12.0991 6.31649 12.0991 5.68332 11.7086 5.2928ZM1.00146 10C1.55375 10 2.00146 10.4477 2.00146 11V14H12.0015V11C12.0015 10.4477 12.4492 10 13.0015 10C13.5538 10 14.0015 10.4477 14.0015 11V15C14.0015 15.5523 13.5538 16 13.0015 16H1.00146C0.44918 16 0.00146484 15.5523 0.00146484 15V11C0.00146484 10.4477 0.44918 10 1.00146 10Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        {share ? <ShareOptn /> : null}
                       */ }
                      <TwitterShareButton
                        style={{ marginRight: "5px", marginTop: "-5px" }}
                        title="Check out my NFT listed on Fusible:"
                        url={recData?"https://app.fusible.io/app/"+recData.n+'/'+recData.t:'/'}
                        hashtags={["fusible", "fusii", "nfts", "fts"]}
                      >
                        <AiOutlineTwitter size={20} color="#000" />
                      </TwitterShareButton>
                    </div>

                    {/* <h6>Cost Here...</h6> */}
                    <p style={{ overflowY: "scroll", maxHeight: "100px" }}>
                      {metaData.result["description"]}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <hr />
                <Tabs defaultActiveKey="1">
                  <TabPane
                    tab={
                      <span>
                        <FaExchangeAlt />
                        &nbsp; BUY
                      </span>
                    }
                    key="1"
                  >
                    {isListed ? (
                      <>
                        <div className="row" style={{ fontSize: "15px" }}>
                          <div
                            className="col-6"
                            style={{
                              display: "inlineBlock",
                              textAlign: "left",
                            }}
                          >
                            <b style={{ color: "#3d81dd" }}>Buy</b> NFT{" "}
                            {/* <input
                            class="tgl tgl-ios"
                            id="cb2"
                            type="checkbox"
                            style={{ display: "none" }}
                          />
                          <label
                            class="tgl-btn"
                            for="cb2"
                            style={{ marginBottom: "0px" }}
                          ></label> */}
                            <Switch onClick={toggler} size="small" /> FT
                          </div>
                          <div className="col-6" style={{ textAlign: "right" }}>
                            <a
                              href={
                                recData
                                  ? "https://exchange.pancakeswap.finance/#/swap?outputCurrency=" +
                                    `${
                                      recData.ftToken ? recData.ftToken : ""
                                    }`
                                  : "https://exchange.pancakeswap.finance/#/swap?outputCurrency="
                              }
                            >
                              Swap FT{" "}
                              <ImArrowUpRight2
                                style={{ position: "relative", top: "-2px" }}
                              />
                            </a>
                            {/* {"  "} <FaLocationArrow fill="#538cdf" /> */}
                            {"  "}
                            <Hint
                              text={"You can also buy/trade FT at pancakeswap"}
                            />
                            {"  "}
                          </div>
                        </div>
                        {toggle ? (
                          
                          <>
                            
                            <div
                              style={{
                                fontSize: "22px",
                                marginTop: "10px",
                              }}
                            >
                              {/*
                              <NumSlider
                                eMax={valueMaxETHFT}
                                sValue={setSliderValue}
                              />*/}
                              {curveData.dBalDecimal ?
                            curveData.dBalDecimal > 0?
                              <div
                                class="input_2"
                                style={{ borderColor: " rgb(61, 129, 221)" }}
                              >
                                <InputNumber
                                  style={{ width: 200, border: "none" }}
                                  defaultValue=""
                                  min="0"
                                  max="10"
                                  step="0.001"
                                  placeholder=""
                                  onChange={(e) => setSliderValue(e)}
                                />{" "}
                                <span>BNB</span>
                              </div>: <div
                                class="input_2"
                                style={{ borderColor: " rgb(61, 129, 221)" }}
                              >
                                <a
                                href={"https://exchange.pancakeswap.finance/#/swap?outputCurrency=" +
                                `${
                                  recData.ftToken ? recData.ftToken : ""
                                }`}
                                className="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                                style={{
                                  width: "100%",
                                  border: "none",
                                  borderRadius: "10px 10px 14px 14px",
                                }}
                              >
                                <span className="nft-card-buy-button-text">{"GET MORE FT HERE"}</span>
                              </a>

                              </div>:''}
                            </div>
                            <div
                              className="row"
                              style={{ fontSize: "11px", paddingTop: "10px" }}
                            >
                              <div
                                className="col-6"
                                style={{
                                  display: "inlineBlock",
                                  textAlign: "left",
                                }}
                              >
                                Available :
                                {curveData.dBalDecimal ? (
                                <span className="p_desc">{  curveData.dBalDecimal }</span>) : ("")}
                              </div>
                              <div
                                className="col-6"
                                style={{
                                  display: "inlineBlock",
                                  textAlign: "right",
                                }}
                              >
                                {" "}
                                YOU GET:{" "}
                                <span className="p_desc">{buyFT + " FT"}</span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              fontSize: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#2e86de",
                            }}
                            className="p_desc"
                          >
                            {/* <AiTwotoneTag /> */}⚡{" "}
                            <>
                              <span
                                style={{
                                  fontSize: "36px",
                                }}
                              >
                                {buyNFT}
                              </span>{" "}
                              BNB
                            </>
                          </div>
                        )}

                        {/* <div style={{ marginBottom: "4px" }}>
                Buy and sell real fusi with digital currency.
                /div>
                <div style={{ marginBottom: "4px" }}>
                  Delivered on demand. <a href="/">Learn more</a>
                </div> */}
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            fontSize: "18px",
                            color: "red",
                            border: "1px solid red",
                            display: "inherit",
                            textAlign: "center",
                          }}
                        >
                          {"NOT LISTED ON FUSIBLE"}
                        </span>{" "}
                      </>
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <AiFillInfoCircle />
                        &nbsp; INFO
                      </span>
                    }
                    key="2"
                  >
                    {metaData ? (
                      <>
                        <div
                          className="row"
                          style={{
                            fontSize: "15px",
                            paddingTop: "3px",
                            textAlign: "center",
                            margin: "2px",
                          }}
                        >
                          <div
                            style={{
                              display: "inlineBlock",
                              textAlign: "left",
                            }}
                          >
                            {metaData.result["name"].substring(0, 8)}
                          </div>
                          <div
                            className="col"
                            style={{
                              display: "inlineBlock",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            tknId:{"#" + recData.t}
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            fontSize: "13px",
                            paddingTop: "3px",
                            textAlign: "center",
                            margin: "2px",
                          }}
                        >
                          <div
                            style={{
                              display: "inlineBlock",
                              textAlign: "left",
                            }}
                          >
                            ROYALTY:
                            {metaData.result["royalty"]
                              ? metaData.result["royalty"].toString() + "%"
                              : null}
                          </div>
                          <div
                            className="col"
                            style={{
                              display: "inlineBlock",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            EDITIONS:
                            {metaData.result["editions"]
                              ? metaData.result["editions"]
                              : null}
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            fontSize: "13px",
                            paddingTop: "3px",
                            textAlign: "center",
                            margin: "2px",
                          }}
                        >
                          <div
                            style={{
                              display: "inlineBlock",
                              textAlign: "left",
                            }}
                          >
                            Owner:
                          </div>
                          <div
                            className="col p_desc"
                            style={{
                              display: "inlineBlock",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            {reSelFT.seller.substring(0, 8)}
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            fontSize: "13px",
                            paddingTop: "3px",
                            textAlign: "center",
                            margin: "2px",
                          }}
                        >
                          <div
                            style={{
                              display: "inlineBlock",
                              textAlign: "left",
                            }}
                          >
                            Creators:
                          </div>
                          <div
                            className="col"
                            style={{
                              display: "inlineBlock",
                              textAlign: "right",
                            }}
                          >
                            {" "}
                            {metaData.result["creators"]
                              ? JSON.parse(metaData.result["creators"]).map(
                                  (c) => {
                                    return (
                                      <>
                                        <span className="p_desc">
                                          {c.address.substring(0, 7) + " "}
                                        </span>
                                      </>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <IoPulseSharp />
                        &nbsp; ACTIVITY
                      </span>
                    }
                    key="3"
                  >
                    <div
                      className="row"
                      style={{ fontSize: "11px", paddingTop: "10px" }}
                    >
                      <div
                        className="col-6"
                        style={{ display: "inlineBlock", textAlign: "left" }}
                      ></div>
                      <div
                        className="col-6"
                        style={{ display: "inlineBlock", textAlign: "right" }}
                      ></div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>

              {/* Button */}
              <div className="buy-btn">
                {isListed ? (
                  <>
                    {toggle ? (
                      <button
                        className="buy-btn-now btn-css"
                        onClick={() => buyFTs()}
                      >
                        <div className="btn-txt">Buy FT </div>
                      </button>
                    ) : (
                      <button
                        className="buy-btn-now btn-css"
                        onClick={() => buy()}
                      >
                        <div className="btn-txt">Buy NFT</div>
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    {/*
                    isOwner ? (
                      
                      <button
                        className="buy-btn-now btn-css"
                        onClick={() => setModalShow(true)}
                      >
                        <div className="btn-txt">List NFT</div>
                      </button>
                    ) : (
                      ""
                    )}*/}
                  </>
                )}
              </div>
            </div>
          </div>
        {/**       
          <CreateModal
            show={modalShow}
            n={recData ? recData.n : undefined}
            t={recData ? recData.t : undefined}
            type={recData ? 2 : undefined}
            onHide={() => setModalShow(false)}
          /> */}
          {/*          <ModalBox show={modalShow} onHide={() => setModalShow(false)} />
           <div className="buy-btn">
          <button className="buy-btn-now btn-css">
            <div className="btn-txt">Buy Parts</div>
          </button>
          <button className="buy-btn-now btn-css">
            <div className="btn-txt">Swap Parts</div>
          </button>
        </div> */}
        </div>
      ) : (
        <div className="container">
        {/*<ToastContainer position="top-center" />*/}
        <div className="row" style={{ marginTop: "25px" }}>
          Connect to Web3 via metamask extension or mobile app.
        </div>
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="column">
            {/* <div>
              <div
                className="ant-row ant-row-space-between create-bottom-row"
                style={{
                  rowGap: "0px",
                  border: "2px solid #3498db",
                  marginBottom: "10px",
                  borderRadius: "12px",
                  padding: "10px",
                }}
              >
                <div class="ant-col-16"></div>
                <div> </div>
              </div>
            </div> */}
            <div className="card">
              <div className="card-parts">
                <div className="image-fusi card-fusii">
                  <SRLWrapper options={options}>
                    {ndata ?
                    !(ndata["mime"].match("mp4") || ndata["mime"].match("quicktime"))?
                     (
                      <img
                        src={ndata.image ? ndata.image : cardImg}
                        alt={ndata["name"]?ndata["name"]:'fusible'}
                        className="image-fusi"
                      />
                    ) : (
                      <>
                        <video
                          className="image-fusi"
                          src={ndata.image}
                          preload={"auto"}
                          autoPlay={true}
                          loop={true}
                          playsInline=""
                          webkit-playsinline=""
                          style={{ width: "100%", height: "100%" }}
                          muted
                          controls
                          
                        ></video>
                      </>
                    ):''}
                  </SRLWrapper>
                </div>
                <div className="bottom-desc">
                  {/*isListed ? (
                    <>
                      <div className="chip ">
                        <div className="row">
                          <div className="col">
                            <p
                              className="left-corner"
                              style={{
                                color: "#fff",
                                fontSize: "11px",
                                textOverflow: "ellipsis",
                              }}
                            >
                              NAME: {tokenURI.name}
                            </p>
                          </div>
                          <div
                            className="col"
                            style={{
                              color: "#fff",
                              fontSize: "9px",
                              textAlign: "right",
                            }}
                          >
                            {/*curveData.dBalDecimal ? (
                              <p>
                                {" "}
                                Avail FT :{" "}
                                {curveData.dBalDecimal +
                                  "/" +
                                  curveData.tSupplyDecimal}{" "}
                              </p>
                            ) : (
                              ""
                            )}

                            {/* <p className="right-corner">Dynamic Pricing Stats</p> 
                          </div>
                        </div>
                        {/**
                <div className="row">
                <p  style={{ color: "#fff", fontSize: "9px", textOverflow: "ellipsis"}} >Desc: {tokenURI.desc}</p>
                  </div> 
                      </div>

                      <div></div>
                    </>
                  ) : (
                    <></>
                  )*/}
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="row" style={{ marginTop: "5px" }}>
          
            <span className="p_desc">{ndata?ndata['name']:""}</span>
         
        </div>
          </div>
      )}
    </>
  );
};

export default AppV0;
