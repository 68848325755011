import React from "react";
import { FaSun } from "react-icons/fa";
import { RiMoonClearFill } from "react-icons/ri";

import Toggle from "./Toggle";
import useDarkMode from "use-dark-mode";

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className="dark-mode-toggle">
      <button
        style={{ paddingBottom: "3px" }}
        type="button"
        onClick={darkMode.disable}
      >
        <FaSun />
      </button>
      <Toggle checked={darkMode.value} onChange={darkMode.toggle} />
      <button
        style={{ paddingBottom: "4px" }}
        type="button"
        onClick={darkMode.enable}
      >
        <RiMoonClearFill />
      </button>
    </div>
  );
};

export default DarkModeToggle;
