import React from "react";

const ReportOptn = () => {
  return (
    <div>
      <div
        data-tippy-root=""
        id="tippy-5"
        style={{
          zIndex: 500,
          visibility: "visible",
          position: "absolute",
          inset: "0px auto auto 0px",
          marginTop: "-6%",
          marginLeft: "30px",
          transform: "translate3d(705px, 613px, 0px)",
        }}
      >
        <div
          className="tippy-box"
          data-state="visible"
          tabindex="-1"
          data-animation="fade"
          role="tooltip"
          style={{ maxWidth: "350px", transitionDuration: " 300ms" }}
          data-placement="bottom"
        >
          <div
            className="tippy-content"
            data-state="visible"
            style={{ transitionDuration: "300ms" }}
          >
            <div>
              <div className="bYZZJZ">
                <div className="exOFdF bbWfCl">
                  <div className="exOFdF evgNzS">
                    <button type="button" className="cpb dIvkOO">
                      <div className="exOFdF bfsfLR">
                        <span className="glhQwU">Report page</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportOptn;
