import React, { useState,useContext } from "react";
import Profile from "./Profile";
import items from "./exploreData";
import ExploreMenu from "./ExploreMenu";
import { Web3Context } from "../Context/Web3context";
//let loaded = false;
import {lsCheck,fetchExplore,fetchOnSale} from "../FetchApis/FetchNFT";
import axios from "axios";
import cardImg from "../images/image-placeholder-square.png";

const Onsale = () => {
  //const [menuItem, setMenuItem] = useState(items);
  const state = useContext(Web3Context);
  const [menuItem, setMenuItem] = useState();
//alert(r.data);
  if(state !== undefined &&menuItem === undefined){
  let p=[];
  let items =[];
  let _onSale=[];
  const onsale =fetchOnSale(state.address);
  //p.push(onsale);
  onsale.then(r=>{
    //alert(r.data);
    setMenuItem(JSON.parse(r.data));
  })
  
  /*fetchExplore().then(response=> {
    console.log(response.data);

    return JSON.parse(response.data);
    //console.log(items.filter(r=>r.FT === onsale))
  
  }).then(i=>{
   console.log(i);
    Promise.all(p).then(r=>{
      console.log(r,i);
      let list =[];
        onsale.then(r=>{
          r.data.map(j=>{
            const nft = i.filter(k=>k.FT==j.FT);
            list.push(nft[0])
       //   console.log(i,r.filter(d=>d.FT === i));
        })
        console.log(list);
        setMenuItem(list);
      })
        
      })
  })*/


    

    //console.log(items.filter(r=>r.FT === onsale))


  

}

  return (
    <>
      <Profile />
      <div className="container" style={{ padding: "2rem" }}>
       {/* <ExploreMenu menuItem={menuItem} />*/}
       
       <center>
         {menuItem?
         <ExploreMenu type="3" menuItem={menuItem} />
         :'Loading'}
        </center>
      </div>
    </>
  );
};

export default Onsale;
