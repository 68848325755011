import React,{useRef} from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
//import {FormData} from "form-data";
import axios from 'axios';

const getVideoCover =  (file, seekTo = 0.0)=> {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
          reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
              reject("video is too short.");
              return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', () => {
              console.log('video is now paused at %ss.', seekTo);
              // define a canvas to have the same dimension as the video
              const canvas = document.createElement("canvas");
              canvas.width = videoPlayer.videoWidth;
              canvas.height = videoPlayer.videoHeight;
              // draw the video frame to canvas
              const ctx = canvas.getContext("2d");
              ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
              // return the canvas image as a blob
              /*ctx.canvas.toBlob(
                  blob => {
                      resolve(blob);
                  },
                  "image/jpeg",
                  0.75 /* quality 
              );*/
              resolve(canvas.toDataURL());
          });
      });
  });
}

function getBase64(file, callback) {
  const reader = new FileReader();

  if(file.type.match('image')){
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file.originFileObj);}
  else{
    try {
      // get the frame at 1.5 seconds of the video file
     getVideoCover(file.originFileObj, 0).then((r)=>{
     // console.log(r);
      callback(r);
    });
      // print out the result image blob
      
      //callback(cover);
  } catch (ex) {
      console.log("ERROR: ", ex);
  }
  }
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/gif" ||
    file.type === "image/jpg" ||
    file.type === "video/mp4" ||
    file.type === "video/quicktime";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/JPEG/PNG/GIF file!");
  }
  //console.log(file.size,file.type);
  const isLt2M = file.size / (1024*1024) < 69;
  
  if (!isLt2M) {
    message.error("Image must smaller than 69MB!");
  }
  return isJpgOrPng && isLt2M;
}
function customRequest (options)  {
  const data = new FormData();
   data.append('file', options.file);
   const config= {
     "headers": {

       "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
     }
   }
   axios.post(options.action, data, config).then((res) => {
     options.onSuccess(res.data, options.file)
   }).catch((err) => {
     console.log(err)
   })
   
 }

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  
  
   handleChange = (info) => {
    // console.log(info);
     //info.event.preventDefault();
    if (info.file.status === "uploading") {
      this.props.reset();
      this.setState({ loading: true });
      //this.preventDefault();
      /*
      let formData = new FormData();
      // add one or more of your files in FormData
      // again, the original file is located at the `originFileObj` key
      formData.append("file", info.fileList[0].originFileObj);
  
      axios
        .post("https://us-central1-fusible-testnet.cloudfunctions.net/uploadFile", formData)
        .then(res => {
          console.log("res", res);
          
        })
        .catch(err => {
          console.log("err", err);
        });*/
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      console.log(info.file.response.hash.IpfsHash);
      this.props.setIpfsHash({ipfs:info.file.response.hash.IpfsHash,mime:info.file.type});
      //let type = info.file.type.match('image');
      getBase64(info.file, (imageUrl) =>
      
        this.setState({
          imageUrl,
          loading: false,
        })
      );
    }
  };


  render() {
    const { loading, imageUrl } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <Upload
        name="NFT file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        method={'post'}
        action="https://api.fusible.xyz/status/upload"
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
       // customRequest={customRequest}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt="avatar"
            style={{ width: "100%", height: "100%" ,objectFit:'contain'}}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

export default Avatar;
