import React,{useContext,useState} from "react";
import "./EditProfile.css";
import { Form, Input, Button } from "antd";
//import { TwitterOutlined } from "@ant-design/icons";
import { Web3Context } from "../Context/Web3context";
import axios from "axios";
import FormData from "form-data";

const { TextArea } = Input;

const EditProfile = () => {
  const state = useContext(Web3Context);
  const [name,setName] = useState();
  const [bio,setBio] = useState();
  const [email,setEmail] = useState();
  const [usrname,setUname] = useState();

const submitProfile = ()=>{
  if(name!==undefined &&email!==undefined&&email!==undefined&&usrname!==undefined){
  let formData = new FormData();
      // add one or more of your files in FormData
      // again, the original file is located at the `originFileObj` key
      //formData.append("file", info.fileList[0].originFileObj);
      formData.append("address",state.address)
      formData.append("name", name);
      formData.append("bio", bio);
      formData.append("email",email);
      formData.append("username",usrname);
      axios
        .post(
          "http://localhost:5001/fusible-testnet/us-central1/createProfile",
          formData
        )
        .then((res) => {
          if(res.data.success)
            alert('Profile Created');
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
}
  return (
    <>
     {state ?(
     <>
      <div className="tltl" style={{ textAlign: "center" }}>
        <h1
          className="p_desc"
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            fontSize: "30px",
          }}
        >
          Edit Profile
        </h1>
      </div>

      <div className="edit-profile">
        <div className="ant-form ant-form-horizontal">
          <div className="nft-create-card-bottom">
            <div className="ant-col">
              <Form layout="vertical">
                <Form.Item label="Address" className="input_1">
                  <Input value={state.address} disabled />
                </Form.Item>
                {/*
                <Form.Item label="Twitter" className="input_1">
                  <Button block>
                    Connect Twitter <TwitterOutlined />
                  </Button>
                </Form.Item>*/}
                <Form.Item label="Name" className="input_1">
                  <Input onChange={(e)=>{setName(e.target.value);}} />
                </Form.Item>
                <Form.Item label="Username" className="input_1">
                  <Input onChange={(e)=>{setUname(e.target.value);}} />
                </Form.Item>
                <Form.Item label="Bio" class="input_1">
                  <TextArea rows={2} placeholder="" maxLength="100" onChange={(e)=>{setBio(e.target.value);}} />
                </Form.Item>
                <Form.Item label="Email" className="input_1">
                  <Input type="email" onChange={(e)=>{setEmail(e.target.value);}} />
                </Form.Item>
                <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button" onClick={submitProfile}>
                <span class="nft-card-buy-button-text" >
                  Submit</span>
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      </>):<center>"Please Connect the wallet to Update your profile!"</center>}
    </>
  );
};

export default EditProfile;
