import React,{useState,useContext} from "react";
import Profile from "./Profile";
import "./Myfts.css";
import { render } from "@testing-library/react";
import { RemoveRedEyeTwoTone } from "@material-ui/icons";
import { CoreV0,Mint} from "../Contract/constant";
import { Ierc721, coreAbi, Ierc20,FTAbi } from "../Contract/abi/abi";
import {fetchFT} from "../FetchApis/FetchNFT";
import { Web3Context } from "../Context/Web3context";


const Myfts = () => {
const [r,setR] = useState();

const state = useContext(Web3Context);
  
  if(state !==undefined && r === undefined){
    let temp =[];
    let p=[];
    fetchFT(state.address).then(i=>{
      //temp = i.data;
      console.log(i.data)
      setR(i.data);
  
      })
  }


  
  return (
    <>
      <Profile />
      {r?r.map(i=>{
        return(<>
        <Rowy nft={i.nft} id={i.tknId}ft={i.FT}bal={i.bal} r={setR} img={i.img} core={i.core} />
        </>)

      }):''}
      
    </>
  );
};

const Rowy = ({nft,id,ft,bal,r,img,coreV0})=>{
  const [status,setStatus] = useState();
  const state = useContext(Web3Context);

  if(state!==undefined){
  const core = new state.web3.eth.Contract(coreAbi, coreV0?coreV0:CoreV0);
  core.methods.isStillListed(ft).call({from:state.address}).then(r=>{setStatus(r)});
  }
  
  const claim =(ft)=>{
    if(state!== undefined && status === false){
      console.log('hey',ft);
      const core = new state.web3.eth.Contract(FTAbi, ft);
      //core.methods.approve(ft,)
      core.methods.burnMyFT().send({from:state.address}).then(i=>{
        r();
      });

    }
  }
  return(<>
      <div className="container">
        {/* <ExploreMenu menuItem={menuItem} />*/}
        {/* <center>WIP</center> */}
        <div className="row" style={{filter: "drop-shadow(rgba(25, 19, 38, 0.15) 0px 1px 4px)" ,   width: "100%",
    background: "rgb(255, 255, 255)",
    borderRadius: "16px",
    margin: "12px 0px"}}>
          <div class="col">
            <div>
              <div class="tblsub"></div>
              <div class="tbldd">
                <div class="tblnump">
                  <div class="tblnumper">
                    <img src={img} style={{objectFit:'cover',width:'90px !important',height:'90px !important'}} />
                  </div>
                </div>
              </div>
            </div>
            </div>
          <div class="col">
                      <div>
                        <div class="tblsub">Token</div>
                        <div class="tbldd">
                          <div class="tblnump">
                            <div class="tblnumper"><a href={'https://bscscan.com/address/'+ft}>{ft.substring(0,5)}</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
           <div class="col">
                      <div>
                        <div class="tblsub">balance</div>
                        <div class="tbldd">
                          <div class="tblnump">
                            <div class="tblnumper">{parseFloat(bal).toFixed(3)}</div>
                          </div>
                        </div>
                      </div>
            </div>
              <div class="col">
                      {status?
                        <a class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                        href={"https://exchange.pancakeswap.finance/#/swap?outputCurrency="+ft}
                        >
                        <span class="nft-card-buy-button-text" >
                          Trade</span>
                        </a>:
                        <a onClick={()=>{claim(ft)}} class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button" style={{background:'#4caf50'}}>
                        <span class="nft-card-buy-button-text" >
                          Claim</span>
                        </a>}
                      
            </div>
          </div>
        
      </div>
    </>)
}

export default Myfts;
