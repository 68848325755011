import React from "react";

const ShareOptn = () => {
  return (
    <div>
      <div
        data-tippy-root=""
        id="tippy-4"
        style={{
          zIndex: 500,
          visibility: "visible",
          position: "absolute",
          inset: "0px auto auto 0px",
          marginTop: "-6%",
          transform: "translate3d(576px, 613px, 0px)",
        }}
      >
        <div
          className="tippy-box"
          data-state="visible"
          tabindex="-1"
          data-animation="fade"
          role="tooltip"
          style={{ maxWidth: "350px", transitionDuration: " 300ms" }}
          data-placement="bottom"
        >
          <div
            className="tippy-content"
            data-state="visible"
            style={{ transitionDuration: "300ms" }}
          >
            <div>
              <div className="bYZZJZ">
                <div className="exOFdF bbWfCl">
                  <div className="gXpHxv">
                    <span className="bRtXqT">Share link to this page</span>
                    <div className="eGqSvC">
                      <div className="jbxvPE jBEPVW">
                        <div className="hnlxBI">
                          <button type="button" className="cpb KGdWz dQqrst">
                            <svg
                              viewBox="0 0 18 16"
                              fill="none"
                              width="13.200000000000001"
                              height="13.200000000000001"
                              xlmns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.9655 2.42676C17.3018 2.71851 16.593 2.91726 15.8468 3.00801C16.6073 2.54976 17.1922 1.82751 17.469 0.965759C16.7558 1.38201 15.9653 1.68501 15.1238 1.85376C14.4518 1.13451 13.494 0.684509 12.4305 0.684509C10.3927 0.684509 8.7405 2.33676 8.7405 4.37226C8.7405 4.66476 8.77425 4.94601 8.83575 5.21526C5.76825 5.07051 3.0495 3.59751 1.23 1.37076C0.90975 1.91226 0.7305 2.54151 0.7305 3.22701C0.7305 4.50951 1.383 5.63676 2.3715 6.29901C1.76625 6.27951 1.197 6.11301 0.7005 5.83701V5.88276C0.7005 7.67151 1.97025 9.16326 3.66 9.50301C3.35025 9.58626 3.02325 9.63126 2.688 9.63126C2.4525 9.63126 2.22675 9.60876 2.001 9.56676C2.47425 11.0315 3.83475 12.0995 5.454 12.1295C4.194 13.1188 2.59725 13.7083 0.8775 13.7083C0.585 13.7083 0.29325 13.691 0 13.658C1.64175 14.7035 3.576 15.3148 5.66775 15.3148C12.4583 15.3148 16.167 9.69276 16.167 4.82526C16.167 4.66851 16.167 4.51026 16.1558 4.35276C16.8765 3.83601 17.5057 3.18276 18.0007 2.44176L17.9655 2.42676Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </button>
                          <div className="oMHNp">
                            <span className="dBQzYR">Twitter</span>
                          </div>
                        </div>
                        <div className="hnlxBI">
                          <button type="button" className="cpb KGdWz dQqrst">
                            <svg
                              viewBox="0 0 24 24"
                              width="13.200000000000001"
                              height="13.200000000000001"
                              xlmns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </button>
                          <div className="oMHNp">
                            <span className="dBQzYR">Facebook</span>
                          </div>
                        </div>
                        <div className="hnlxBI">
                          <button type="button" className="cpb KGdWz dQqrst">
                            <svg
                              viewBox="0 0 16 14"
                              fill="none"
                              width="13.200000000000001"
                              height="13.200000000000001"
                              xlmns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.9513 1.29916L13.5438 13.1556C13.377 13.997 12.8902 14.1987 12.21 13.8093L8.542 10.979L6.76804 12.7662C6.56797 12.9748 6.40125 13.1556 6.03445 13.1556C5.55428 13.1556 5.63431 12.9679 5.47425 12.495L4.20714 8.19051L0.572523 7.00834C-0.214421 6.76495 -0.22109 6.20168 0.745918 5.7914L14.9243 0.0891779C15.5711 -0.209841 16.1914 0.256072 15.9446 1.29221L15.9513 1.29916Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </button>
                          <div className="oMHNp">
                            <span className="dBQzYR">Telegram</span>
                          </div>
                        </div>
                        <div className="hnlxBI">
                          <button type="button" className="cpb KGdWz dQqrst">
                            <svg
                              viewBox="0 0 24 24"
                              width="13.200000000000001"
                              height="13.200000000000001"
                              xlmns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </button>
                          <div className="oMHNp">
                            <span className="dBQzYR">E-mail</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareOptn;
