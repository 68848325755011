import React, { useState, useContext } from "react";
import Navtab from "./Navtab";
import ReportOptn from "./ReportOptn";
import ShareOptn from "./ShareOptn";
import fusiiLogo from "../images/fusii-logo.png";
import { FaYoutube } from "react-icons/fa";
import { Web3Context } from "../Context/Web3context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import ava from "../images/avatar.png";
import { lsCheck, fetchNFTDataPerAccount } from "../FetchApis/FetchNFT";
import axios from "axios";

const getData = (address) => {
  let items = [];
  let p = [];
  let data;
  let myNFT = [];

  fetchNFTDataPerAccount(address, (result) => {
    console.log(result);

    const _nft = result.data.filter((item) => item.type === "nft");

    p.push(
      _nft.map((id) => {
        let d = {};
        d.nft = id.contract_address;
        if (id.nft_data !== null)
          id.nft_data.map((i) => {
            d.creator = [i.owner];
            d.tknId = i.token_id;
            console.log(i);
            if (i.token_url.match("https://") || i.token_url.match("ipfs://")) {
              if (i.token_url.match("https://cloudflare-ipfs.com/"))
                i.token_url = i.token_url.replace(
                  "https://cloudflare-ipfs.com/",
                  "https://ipfs.io/ipfs/"
                );

              p.push(
                axios.get(i.token_url).then((r) => {
                  d.name = r.data.name;
                  d.title = r.data.name;
                  d.image = r.data.image;
                  d.category = r.data.mimetype
                    ? r.data.mimetype.match("image")
                      ? "Image"
                      : "Video"
                    : "Image";
                  d.mime = r.data.mimetype ? r.data.mimetype : "image/png";
                  d.royalty = r.data.royalty ? r.data.royalty : null;

                  //console.log(r.data.name);
                })
              );
            } else {
              d.metadata = {};
            }

            Promise.all(p).then((r) => {
              items.push(d);
              //console.log(items);
            });
          });
      })
    );
    Promise.all(p).then((r) => {
      console.log(items);
      data = {
        items: items,
        lastUpdated: Math.floor(new Date().getTime() / 1000),
        addr: address,
      };
      data = JSON.stringify(data);
      //data = explorer;
      sessionStorage.setItem("myNFT", data);
    });
    /*
items.push({
          
          image: id.metadata.image,
          title: id.metadata.name,
          nft: id.nftAddress,
          tknId: id.tokenId,
          FT: (id.FT)?id.FT:null,
          category: id.metadata.mimetype?id.metadata.mimetype.match('image')?'Image':'Video':'Image',
          name: id.metadata.name,
          creator: id.metadata.creators?id.metadata.creators:id.owner,
          blockNum:id.blockNum,
          listedPrice: 0,
          seller: id.seller,
          royalty:id.metadata.royalty?id.metadata.royalty:null,
          mime:(id.metadata.mimetype)?id.metadata.mimetype:'image/png',
          editions:id.metadata.editions?id.metadata.editions:'1'
          });
  result.assets.map((id,i=0)=>{
    let type=true;
    //console.log(id,i);
    
    if(id.image_url.match('mp4')||id.image_url.match('mov'))  type=false;
    items.push({  id: i,
      image: id.image_url,
      title: id.asset_contract.name,
      nft: id.asset_contract.address,
      tknId: id.token_id,
      category: id.asset_contract.address,
      name:id.name,
      creator:id.creator.address,
      type: type});
    i++;
  });*/
  });
};

const Profile = () => {
  const [drop, setDrop] = useState(false);
  const [share, setShare] = useState(false);
  const state = useContext(Web3Context);
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  //console.log(sessionStorage);
  /*
  if(state !== undefined){
  lsCheck(sessionStorage.getItem("myNFT"),(e)=>{ 
    console.log(e,state.address);
    let exp= JSON.parse(e);
    let timeNow = Math.floor(new Date().getTime()/1000);
    if(e === null) getData(state.address);
    //console.log(exp);
    else if(e !== null && exp.addr !== state.address ) getData(state.address);
    else if(e !== null && (timeNow-exp.lastUpdated) > 120) getData(state.address);
    

    //setMenuItem(exp.items);
    //setSliderData(exp.slide)
  }); 
 }*/

  return (
    <div style={{ paddingBottom: "135px !important" }}>
      <div className="pp">
        {state ? (
          <>
            {/* Profile Header */}
            <div className="pp1 pp2">
              <div className="pph pp1">
                <div className="ppi pp1">
                  <div className="ppi1">
                    <div className="ppimg">
                      <img
                        style={{ height: "100%", width: "100%" }}
                        alt="Identicon"
                        
                        src={ava}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Profile Name */}
            <div className="pnt">
              <div className="pntx pp1">
                <div className="pntxt">
                  <div className="pp1">
                    <div className="pht">
                      <span className="pst pstx">
                        <span className="pstxt">
                          {state
                            ? state.address.substring(0, 5) +
                              "..." +
                              state.address.substr(-3)
                            : "0xABcD...xYZ"}
                        </span>
                        <span className="rms"></span>
                        <button type="button" className="cpb">
                          <span className="cpbt">
                            {/* Copy works here */}
                            <CopyToClipboard
                              text={state.address}
                              onCopy={onCopyText}
                            >
                              <span>
                                {isCopied ? (
                                  "Copied!"
                                ) : (
                                  <MdContentCopy
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      margin: "10px",
                                    }}
                                  />
                                )}
                              </span>
                            </CopyToClipboard>
                          </span>
                        </button>
                      </span>
                      {/* Social Account 

                      <div class="eIGEyX">
                        <div class="fkBquX hSKhZv">
                          <a
                            href="https://twitter.com/"
                            target="_blank"
                            className="fnyloc eesVrg ktlGIb sc-gpEHUl faFrwe"
                          >
                            <svg
                              viewBox="0 0 18 16"
                              fill="none"
                              width="14"
                              height="14"
                              xlmns="http://www.w3.org/2000/svg"
                              class="kmErAh"
                            >
                              <path
                                d="M17.9655 2.42676C17.3018 2.71851 16.593 2.91726 15.8468 3.00801C16.6073 2.54976 17.1922 1.82751 17.469 0.965759C16.7558 1.38201 15.9653 1.68501 15.1238 1.85376C14.4518 1.13451 13.494 0.684509 12.4305 0.684509C10.3927 0.684509 8.7405 2.33676 8.7405 4.37226C8.7405 4.66476 8.77425 4.94601 8.83575 5.21526C5.76825 5.07051 3.0495 3.59751 1.23 1.37076C0.90975 1.91226 0.7305 2.54151 0.7305 3.22701C0.7305 4.50951 1.383 5.63676 2.3715 6.29901C1.76625 6.27951 1.197 6.11301 0.7005 5.83701V5.88276C0.7005 7.67151 1.97025 9.16326 3.66 9.50301C3.35025 9.58626 3.02325 9.63126 2.688 9.63126C2.4525 9.63126 2.22675 9.60876 2.001 9.56676C2.47425 11.0315 3.83475 12.0995 5.454 12.1295C4.194 13.1188 2.59725 13.7083 0.8775 13.7083C0.585 13.7083 0.29325 13.691 0 13.658C1.64175 14.7035 3.576 15.3148 5.66775 15.3148C12.4583 15.3148 16.167 9.69276 16.167 4.82526C16.167 4.66851 16.167 4.51026 16.1558 4.35276C16.8765 3.83601 17.5057 3.18276 18.0007 2.44176L17.9655 2.42676Z"
                                fill="#1da1f2"
                              ></path>
                            </svg>
                            <span class="eyxiok">Fusible Official</span>
                          </a>
                          <a
                            href="https://www.youtube.com/"
                            target="_blank"
                            class="fnyloc eesVrg ktlGIb faFrwe"
                          >
                            <FaYoutube
                              fill="red"
                              width="14"
                              height="14"
                              class="kmErAh"
                              style={{ marginBottom: "3.5px" }}
                            />
                            <span class="eyxiok">Fusible Channel</span>
                          </a>
                        </div>
                      </div>
                      <div className="btne"></div>

                      {/* Profile Buttons 
                      <div className="prbtn">
                        <div className="pp1">
                          <button type="button" className="fbtn flbtn cpb">
                            <img
                              src={fusiiLogo}
                              style={{ width: "25px", height: "25px" }}
                              alt="fusii-logo"
                            />{" "}
                            200 Fusi
                          </button>
                        </div>
                        <div className="ppb2">
                          <div className="pbt2">
                            <button
                              onClick={() => setShare(!share)}
                              type="button"
                              className="cpb pbt3 pbt4"
                            >
                              <svg
                                viewBox="0 0 15 16"
                                fill="none"
                                width="13.200000000000001"
                                height="13.200000000000001"
                                xlmns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M11.7086 5.2928L7.00146 0.585693L2.29436 5.2928C1.90383 5.68332 1.90383 6.31649 2.29436 6.70701C2.68488 7.09754 3.31805 7.09754 3.70857 6.70701L6.00146 4.41412V11C6.00146 11.5523 6.44918 12 7.00146 12C7.55375 12 8.00146 11.5523 8.00146 11V4.41412L10.2944 6.70701C10.6849 7.09754 11.318 7.09754 11.7086 6.70701C12.0991 6.31649 12.0991 5.68332 11.7086 5.2928ZM1.00146 10C1.55375 10 2.00146 10.4477 2.00146 11V14H12.0015V11C12.0015 10.4477 12.4492 10 13.0015 10C13.5538 10 14.0015 10.4477 14.0015 11V15C14.0015 15.5523 13.5538 16 13.0015 16H1.00146C0.44918 16 0.00146484 15.5523 0.00146484 15V11C0.00146484 10.4477 0.44918 10 1.00146 10Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="ppb2">
                          <div className="pbt2">
                            <button
                              onClick={() => setDrop(!drop)}
                              type="button"
                              className="cpb pbt3 pbt4"
                            >
                              <svg
                                viewBox="0 0 14 4"
                                fill="none"
                                width="13.200000000000001"
                                height="13.200000000000001"
                                xlmns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M3.5 2C3.5 2.82843 2.82843 3.5 2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2ZM8.5 2C8.5 2.82843 7.82843 3.5 7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2ZM11.999 3.5C12.8274 3.5 13.499 2.82843 13.499 2C13.499 1.17157 12.8274 0.5 11.999 0.5C11.1706 0.5 10.499 1.17157 10.499 2C10.499 2.82843 11.1706 3.5 11.999 3.5Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>*/}
                      {drop ? <ReportOptn /> : null}
                      {share ? <ShareOptn /> : null}
                    </div>
                  </div>
                  {/* Collection Tabs */}
                  <Navtab />
                </div>
              </div>
            </div>
          </>
        ) : (
          <h2>
            <center>"Please Connect Wallet"</center>
          </h2>
        )}
      </div>
    </div>
  );
};

export default Profile;
