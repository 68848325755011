import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import ExploreCHead from "./ExploreCHead";
import CreateModal from "./CreateModal";
import Delist from "./Delist";
import { Button } from "antd";
import bscLogo from "../images/bsc.svg";
import bnbSmall from "../images/bnb.svg";
import { CoreV0 } from "../Contract/constant";

const ExploreMenu = ({ menuItem, type }) => {
  const [modalShow, setModalShow] = useState(false);
  const [deShow, setDeShow] = useState(false);
  const [n, setN] = useState();
  const [t, setT] = useState();
  const [nid, setNid] = useState();
  const [ft, setFT] = useState();

  console.log(menuItem);

  const [visible, setVisible] = useState(
    menuItem ? (menuItem.length > 8 ? 8 : menuItem.length) : 0
  );

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  const listNFT = (n, t) => {
    setN(n);
    setT(t);
    setModalShow(true);
  };
  const deList = (_ft, _n, _t) => {
    setNid({ nft: _n, id: _t });
    setFT(_ft);
    setDeShow(true);
  };
  const reset = () => {
    setNid();
    setFT();
    setDeShow(false);
  };

  return (
    <>
      <CreateModal
        show={modalShow}
        n={n}
        t={t}
        type={2}
        onHide={() => setModalShow(false)}
      />
      <Delist show={deShow} nid={nid} ft={ft} onHide={() => reset()} />
      <div className="item_e">
        {menuItem
          ? menuItem.slice(0, visible).map((item) => {
              return (
                <div className="item_con" key={item.nft + "_" + item.tknId}>
                  <div className="item_container">
                    <div
                      className="row"
                      style={{
                        fontSize: "12px",
                        padding: "3px",

                        borderBottom: "1px solid #74b9ff",
                      }}
                    >
                      <div
                        className="col-4 p_desc"
                        style={{
                          display: "inlineBlock",
                          textAlign: "left",
                          maxHeight: "19px !important",
                        }}
                      >
                        <img src={bscLogo} className="netLogo" alt="bscLogo" />
                        {" BSC "}
                      </div>
                      <div
                        className="col-2 p_desc"
                        style={{ display: "inlineBlock", textAlign: "right" }}
                      ></div>
                      <div
                        className="col-6 p_desc"
                        style={{ display: "inlineBlock", textAlign: "right" }}
                      >
                        {" "}
                        Tkn : {"#" + item.tknId}
                      </div>
                    </div>
                    {/*<ExploreCHead />*/}
                    <>
                      <NavLink
                        exact
                        to={item.core?
                          item.core !== CoreV0?"/app/"+item.core+"/" + item.nft + "/" + item.tknId:"/app/"+ item.nft + "/" + item.tknId:"/app/"+ item.nft + "/" + item.tknId}
                        className="row"
                        style={{
                          fontSize: "12px",

                          borderBottom: "1px solid #74b9ff",
                        }}
                      >
                        {item.mime.match("image") ? (
                          <img
                            src={item.image}
                            alt=""
                            style={{
                              height: "240px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <>
                            <video
                              playsInline
                              src={item.image}
                              controls
                              preload={"auto"}
                              autoPlay={true}
                              loop={true}
                              muted
                              style={{
                                width: "100%",
                                height: "240px",
                                maxHeight: "360px !important",
                              }}
                            ><source src={item.image} type={item.mime}></source></video>
                          </>
                        )}
                      </NavLink>
                    </>
                    <div
                      className="row"
                      style={{
                        fontSize: "16px",
                        paddingTop: "3px",
                        textAlign: "left",
                        margin: "2px",
                      }}
                    ></div>
                    <div
                      className="ant-row ant-row-space-between create-bottom-row"
                      style={{
                        rowGap: "0px",
                        marginTop: "2px",
                        padding: "2px",
                      }}
                    >
                      <div className="p_desc">
                        <b>
                          {item.name.length > 15
                            ? item.name.substring(0, 11) + " .."
                            : item.name}
                        </b>
                      </div>
                      <div>
                        <img
                          style={{ width: "14px", padding: "1px" }}
                          src={bnbSmall}
                          alt="bnbImage"
                        />
                        {item.listedPrice}
                      </div>
                    </div>
                    <div
                      className="ant-row ant-row-space-between create-bottom-row"
                      style={{
                        rowGap: "0px",
                        marginTop: "2px",
                        padding: "2px",
                      }}
                    >
                      {type === "2" ? (
                        <div className="creator">
                          <p className="crby" style={{ margin: "0" }}>
                            Creator
                          </p>
                          <p className=" p_desc" style={{ font: "8px" }}>
                            {" "}
                            {item.creator
                              ? item.creator.length > 1
                                ? item.creator[0].substring(0, 5) + ".."
                                : item.creator[0].substring(0, 5)
                              : null}
                          </p>
                        </div>
                      ) : (
                        <div className="creator">
                          <p className="crby" style={{ margin: "0" }}>
                            Creator :
                          </p>
                          <p
                            className="p_desc"
                            style={{
                              fontSize: "14px",
                              marginLeft: "2px",
                              marginTop: "1px",
                            }}
                          >
                            {item.creator &&
                            JSON.parse(item.creator)[0].address !== undefined
                              ? JSON.parse(item.creator).length > 1
                                ? JSON.parse(item.creator)[0].address.substring(
                                    0,
                                    5
                                  ) + ".."
                                : JSON.parse(item.creator)[0].address.substring(
                                    0,
                                    5
                                  )
                              : null}
                          </p>
                        </div>
                      )}
                      {item.seller ? (
                        <div className="creator">
                          <p className="crby" style={{ margin: "0" }}>
                            Owner :
                          </p>
                          <p
                            className="p_desc"
                            style={{
                              fontSize: "14px",
                              marginLeft: "2px",
                              marginTop: "1px",
                            }}
                          >
                            {item.seller.substring(0, 5)}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    {/*
                <p
                  style={{
                    marginLeft: "85%",
                    marginTop: "-33px",
                    display: "block",
                    width: "fit-content",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<FavoriteBorder />}
                        checkedIcon={<Favorite />}
                        name="checkedH"
                      />
                    }
                  />
                </p>*/}
                  </div>
                  {type === "2" ? (
                    <div
                      className="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                      style={{
                        width: "100%",
                        border: "none",
                        borderRadius: "0 0 14px 14px",
                      }}
                      data-nft={item.nft + "-" + item.tknId}
                      onClick={() => {
                        listNFT(item.nft, item.tknId);
                      }}
                    >
                      <span className="nft-card-buy-button-text">{"LIST"}</span>
                    </div>
                  ) : type !== "3" ? (
                    <>
                      {item.onsale ? (
                        <NavLink
                          exact
                          to={"/app/" + item.nft + "/" + item.tknId}
                          className="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                          style={{
                            width: "100%",
                            border: "none",
                            borderRadius: "0 0 14px 14px",
                          }}
                        >
                          <span className="nft-card-buy-button-text">
                            {"BUY"}
                          </span>
                        </NavLink>
                      ) : (
                        <NavLink
                          exact
                          to={"/app/" + item.nft + "/" + item.tknId}
                          className="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                          style={{
                            width: "100%",
                            border: "none",
                            borderRadius: "0 0 14px 14px",
                            background: "#f44336",
                          }}
                        >
                          <span className="nft-card-buy-button-text">
                            {"SOLD"}
                          </span>
                        </NavLink>
                      )}
                    </>
                  ) : (
                    <>
                      {item.onsale ? (
                        <div
                          className="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                          style={{
                            width: "100%",
                            border: "none",
                            borderRadius: "0 0 14px 14px",
                          }}
                          onClick={() => {
                            deList(item.FT, item.nft, item.tknId);
                          }}
                        >
                          <span className="nft-card-buy-button-text">
                            {"Manage"}
                          </span>
                        </div>
                      ) : (
                        <NavLink
                          exact
                          to={"/app/" + item.nft + "/" + item.tknId}
                          className="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                          style={{
                            width: "100%",
                            border: "none",
                            borderRadius: "0 0 14px 14px",
                            background: "#f44336",
                          }}
                        >
                          <span className="nft-card-buy-button-text">
                            {"SOLD"}
                          </span>
                        </NavLink>
                      )}
                    </>
                  )}
                </div>
              );
            })
          : ""}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Button
          style={{
            borderRadius: "20px",
            backgroundColor: "aliceblue",
          }}
          size="large"
          onClick={showMoreItems}
        >
          Load More
        </Button>
      </div>
    </>
  );
};

export default ExploreMenu;
