import React, { useState, useContext, useEffect } from "react";
import { Form, Input, InputNumber, Switch, Steps, message } from "antd";
import Avatar from "./Avatar";
import { Web3Context } from "../Context/Web3context";
import { Ierc721, coreAbi, MintAbi } from "../Contract/abi/abi";
import { MINTER_ROLE, Mint, MintFee } from "../Contract/constant";
import axios from "axios";
import FormData from "form-data";
import CreateModal from "./CreateModal";

const { TextArea } = Input;
const { Step } = Steps;
const MintNFT = () => {
  const state = useContext(Web3Context);
  const [ipfsHash, setIpfsHash] = useState();
  const [isMinter, setIsMinter] = useState(true);
  const [nftMinted, setMinted] = useState(true);
  const [nftName, setNFTName] = useState();
  const [nftDesc, setNFTDesc] = useState();
  const [nftData, setNFTData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [owners, setOwners] = useState();
  const [ownerState, setOwnerState] = useState(true);
  const [royalty, setRoyalty] = useState(0);
  const [editions, setEditions] = useState(1);
  const [split, setSplit] = useState();
  const [own, setOwn] = useState();
  const [stps, setStps] = useState(-1);
  const [nit,setNit]= useState();

  //console.log(state);
  const reset = () => {
    setIpfsHash();
    setMinted(true);
    setNFTName();
    setNFTDesc();
    setNFTData();
  };
  const toggler = () => {
    if (!toggle) {
      let c = [{ address: state.address, split: 100 }];
      setOwners(c);
    }
    toggle ? setToggle(false) : setToggle(true);
    // ownerState?setOwnerState(false):setOwnerState(true);
  };
  const ownToggler = () => {
    ownerState ? setOwnerState(false) : setOwnerState(true);
  };
  const checkIfMinter = (contract) => {
    contract.methods
      .hasRole(MINTER_ROLE, state.address)
      .call({ from: state.address })
      .then((r) => {
        r ? setIsMinter(true) : setIsMinter(false);
      });
  };
  const setName = (e) => {
    setNFTName(e.target.value);
  };
  const setDesc = (e) => {
    setNFTDesc(e.target.value);
  };
  const mintNFT = () => {
    if (
      state !== undefined &&
      nftName !== undefined &&
      nftDesc !== undefined &&
      ipfsHash !== undefined &&
      royalty !== undefined &&
      royalty >= 0 &&
      editions !== undefined &&
      editions > 0 &&
      owners !== undefined &&
      owners.length > 0
    ) {
      let o = [];
      let s = [];
      owners.map((i) => {
        o.push(i.address);
        s.push(i.split);
      });
      const mint = new state.web3.eth.Contract(MintAbi, Mint);
      console.log("Are we minting");
      let formData = new FormData();
      // add one or more of your files in FormData
      // again, the original file is located at the `originFileObj` key
      //formData.append("file", info.fileList[0].originFileObj);
      formData.append("name", nftName);
      formData.append("desc", nftDesc);
      formData.append("royalty", royalty);
      formData.append("editions", editions);
      formData.append("creators", JSON.stringify(owners));
      formData.append("img", "ipfs://" + ipfsHash.ipfs);
      formData.append("type", ipfsHash.mime);
      axios
        .post(
          "https://us-central1-fusible-testnet.cloudfunctions.net/pinataUploadIpfsJson",
          formData
        )
        .then((res) => {
          setStps(1);
          message.info("Form submited. Minting initiating");
          console.log("resJSON:", res.data.hash.IpfsHash);
          if (res.data.hash.IpfsHash !== undefined) {
            mint.methods
              .safeMint(
                "ipfs://" + res.data.hash.IpfsHash,
                o,
                royalty,
                s,
                editions
              )
              .send({ from: state.address, value: MintFee })
              .then((r) => {
                if (r !== undefined) {
                  setIpfsHash();
                  let result = r.events.Transfer;
                  let tokenId = [];
                  if (r.events.Transfer.length > 1) {
                    result.map((res) => {
                      let i = res.returnValues;
                      tokenId.push(i["tokenId"]);
                    });
                  } else {
                    let i = result.returnValues;
                    tokenId.push(i["tokenId"]);
                  }
                  console.log(r);
                  setNFTData({
                    conAddress: Mint,
                    tokenId: tokenId,
                    txn: r.transactionHash,
                    royalty: royalty,
                    editions: editions,
                    owners: owners,
                  });
                  setMinted(false);
                  setStps(2);
                }
              })
              .catch((e) => message.error("Minting failed!"));
          }
        })
        .catch((err) => {
          message.error("Argh Server isn't responding atm!");
          console.log("err", err);
        });
    }
  };
  useEffect(() => {
    if (state !== undefined) {
      const mint = new state.web3.eth.Contract(MintAbi, Mint);
      //checkIfMinter(mint);
      let c = [{ address: state.address, split: 100 }];

      setOwners(c);
    }
  }, [state]);

  const updateOwner = () => {
    owners.map((i, c = 0) => {
      c++;
      return (
        <>
          <div
            className="ant-row ant-row-space-between create-bottom-row"
            style={{ rowGap: "0px", padding: "5px" }}
          >
            <div>{c}.</div>
            <div>{i.address.substring(0, 15)}</div>
            <div>Split : {i.split} </div>
          </div>
        </>
      );
    });
  };
  const AddOwners = () => {
    console.log(split, own);
    if (split !== undefined && own !== undefined) {
      let o = owners;
      if (o[0].split > split) o[0].split = o[0].split - split;
      else return;
      o.push({ address: own, split: split });
      console.log(o);
      setOwners(o);
      ownToggler(true);
    }
  };
  const setListData = (n,i,t)=>{
    setNit({n,i,t});
    setModalShow(true);
  }
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h1
          className="p_desc"
          style={{ marginTop: "25px", marginBottom: "25px", fontSize: "30px" }}
        >
          MINT NFT{" "}
        </h1>
      </div>
      <div style={{ padding: "10px" }} className="nft-create-card">
        <Steps current={stps} size="small">
          <Step title="Upload" />
          <Step title="Fill Form" />
          <Step title="Mint" />
        </Steps>
      </div>
      <div className="nft-create-card">
        {state ? (
          <>
          
            
            {isMinter ? (
              <>
                <div className="ant-form ant-form-horizontal">
                  <div className="nft-img-upload-cover">
                    <div style={{ paddingTop: "30px" }}>
                      <Avatar reset={reset} setIpfsHash={setIpfsHash} />
                    </div>
                  </div>
                  {nftMinted ? (
                    <>
                      <div className="nft-create-card-bottom">
                        <div className="ant-col">
                          <Form>
                            <div class="input_1">
                              <Input
                                placeholder="NFT Name"
                                required
                                onChange={setName}
                              />
                            </div>
                            <div class="input_1">
                              <TextArea
                                rows={2}
                                placeholder="NFT Description"
                                onChange={setDesc}
                              />
                            </div>
                            {/*
              <div class="input_2">
                <InputNumber
                  style={{ width: 200, border: "none" }}
                  defaultValue=""
                  min="0"
                  max="10"
                  step="0.00000000000001"
                  placeholder="NFT Price"
                  stringMode
                />{" "}
                <span>BNB</span>
              </div>*/}
                          </Form>
                          {ipfsHash ? (
                            <>
                              <div
                                className="ant-row ant-row-space-between create-bottom-row"
                                style={{ rowGap: "0px", padding: "5px" }}
                              >
                                <div class="input_1">
                                  Editions:
                                  <InputNumber
                                    defaultValue="1"
                                    placeholder="1-100"
                                    onChange={(e) => setEditions(e)}
                                    style={{
                                      border: "none",
                                      width: "85px",
                                      borderRadius: "10px",
                                      padding: "none !important",
                                      marginLeft: "5px",
                                    }}
                                    min="1"
                                    max="100"
                                  />{" "}
                                </div>
                                <div class="input_1">
                                  Royalty:
                                  <InputNumber
                                    defaultValue="0"
                                    placeholder="%"
                                    onChange={(e) => setRoyalty(e)}
                                    style={{
                                      border: "none",
                                      width: "85px",
                                      borderRadius: "10px",
                                      padding: "none !important",
                                      marginLeft: "5px",
                                    }}
                                    min="0"
                                    max="20"
                                  />{" "}
                                </div>
                              </div>

                              <div
                                className="ant-row ant-row-space-between create-bottom-row"
                                style={{ rowGap: "0px", padding: "5px" }}
                              >
                                <div class="input_1">
                                  Collab:
                                  <Switch
                                    onClick={toggler}
                                    size="small"
                                    style={{
                                      marginLeft: "5px",
                                      marginTop: "-2px",
                                    }}
                                  />
                                </div>
                              </div>

                              {toggle ? (
                                <>
                                  {ownerState !== undefined ? (
                                    <>
                                      {owners.map((i, c = 0) => {
                                        c++;
                                        return (
                                          <>
                                            <div
                                              className="ant-row ant-row-space-between create-bottom-row"
                                              style={{
                                                rowGap: "0px",
                                                padding: "5px",
                                              }}
                                            >
                                              <div className="text-dark2">
                                                {c}.
                                              </div>
                                              <div className="text-dark2">
                                                {i.address.substring(0, 15)}
                                              </div>
                                              <div className="text-dark2">
                                                Split : {i.split}{" "}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    className="ant-row ant-row-space-between create-bottom-row"
                                    style={{ rowGap: "0px", padding: "5px" }}
                                  >
                                    <div>
                                      <Input
                                        style={{
                                          border: "1px solid #8a8a8e",
                                          width: "125px",
                                          borderRadius: "10px",
                                          padding: "none !important",
                                        }}
                                        onChange={(e) => setOwn(e.target.value)}
                                        placeholder="Owner"
                                        type="text"
                                      />
                                    </div>

                                    <div class="input_1">
                                      <InputNumber
                                        onChange={(e) => setSplit(e)}
                                        style={{
                                          border: "none",
                                          borderRadius: "10px",
                                          padding: "none !important",
                                          width: "85px",
                                        }}
                                        placeholder="Split"
                                        min="0"
                                        max="100"
                                      />{" "}
                                    </div>
                                    <button
                                      onClick={AddOwners}
                                      syle={{ borderRadius: "10px" }}
                                      class="ant-btn ant-btn-primary"
                                    >
                                      ADD
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div
                                className="ant-row ant-row-space-between create-bottom-row"
                                style={{ rowGap: "0px", padding: "5px" }}
                              >
                                <div className="text-dark2">Mint Fee</div>
                                <div className="text-dark2">0.001</div>
                              </div>
                              <div
                                className="ant-row ant-row-space-between create-bottom-row"
                                style={{ rowGap: "0px" }}
                              >
                                {/*}
              <div style={{ padding: "5px" }}>You will receive</div>*/}
                                <div></div>

                                <button
                                  class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                                  onClick={mintNFT}
                                >
                                  <span className="nft-card-buy-button-text">
                                    Mint NFT
                                  </span>
                                </button>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="nft-create-card-bottom">
                        <div className="ant-col">
                          <div
                            className="ant-row ant-row-space-between create-bottom-row"
                            style={{
                              rowGap: "0px",
                              padding: "5px",
                              color: "green",
                            }}
                          >
                            <div>Your NFT has been Minted</div>
                            <div>
                              {nftData ? (
                                <>
                                  <a
                                    href={
                                      "https://bscscan.com/tx/" + nftData.txn
                                    }
                                  >
                                    {"txid: " + String.fromCharCode(8599)}
                                  </a>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div
                            className="ant-row ant-row-space-between create-bottom-row"
                            style={{ rowGap: "0px", padding: "5px" }}
                          >
                            <div className="text-dark2">{"Name: " + nftName}</div>
                            <div className="text-dark2">{"Royalty: " + royalty}</div>
                            {/*<div>nftDesc}</div>*/}
                          </div>
                          <div
                            className="ant-row ant-row-space-between create-bottom-row"
                            style={{
                              rowGap: "0px",
                              padding: "5px",
                            }}
                          >
                            <div className="text-dark2">
                              Contract:
                              <a href={"https://bscscan.com/address/" + Mint}>
                                {Mint.substring(0, 5)}
                              </a>
                            </div>
                            <div className="text-dark2">
                              Id:
                              {nftData ? (
                                <>
                                  {nftData.tokenId.map((id, i) => {
                                    return (
                                      <>
                                        {i < 2 ? (
                                          <>
                                            <span
                                              style={{
                                                padding: "2px",
                                                margin: "1px",
                                                border:
                                                  "0.5px solid rgb(24,144,255)",
                                                fontSize: "11px",
                                                borderRadius: "2px",
                                              }}
                                            >
                                              {id}
                                            </span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    );
                                  })}

                                  {nftData.tokenId.length > 1 ? (
                                    <>
                                      <span
                                        style={{
                                          padding: "2px",
                                          margin: "1px",
                                          border: "0.5px solid rgb(24,144,255)",
                                          fontSize: "11px",
                                          borderRadius: "2px",
                                        }}
                                      >
                                        {".."}
                                      </span>
                                      <span
                                        style={{
                                          padding: "2px",
                                          margin: "1px",
                                          border: "0.5px solid rgb(24,144,255)",
                                          fontSize: "11px",
                                          borderRadius: "2px",
                                        }}
                                      >
                                        {
                                          nftData.tokenId[
                                            nftData.tokenId.length - 1
                                          ]
                                        }
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <button
                          class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"
                          onClick={() => {
                            //setType(2);
                            setListData(Mint,nftData.tokenId[0],2);
                          }}
                        >
                          <span className="nft-card-buy-button-text">
                            LIST NFT
                          </span>
                        </button>
                        <CreateModal
                          show={modalShow}
                          n={Mint}
                          t={nit ? nit.i: undefined}
                          type={nit?nit.t:undefined}
                          onHide={() => setModalShow(false)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <h4 style={{ padding: "5px" }}>
                <center>
                  Please Contact US on
                  <span>
                    <a
                      href="https://discord.com/channels/828656574527569930/828656804622762081"
                      style={{ color: "blueviolet" }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      DISCORD
                    </a>
                  </span>
                  to get the Creator role to Mint NFT
                </center>
              </h4>
            )}
          </>
        ) : (
          <h4 style={{ padding: "5px" }}>
            <center>Please connect to Metamask web3 wallet</center>
          </h4>
        )}
      </div>
    </>
  );
};

export default MintNFT;
