import React from "react";
import AzukiAvatar from "../../images/azuki_img.png";

const Slide2 = () => {
  return (
    <div>
      <div>
        <div className="imgDiv" style={{ marginBottom: 20 }}>
          <div className="gridImg">
            <img src={AzukiAvatar} alt="AzukiAvatar" />
            <img src={AzukiAvatar} alt="AzukiAvatar" />
            <img src={AzukiAvatar} alt="AzukiAvatar" />
            <img src={AzukiAvatar} alt="AzukiAvatar" />
          </div>
          <div className="imgDetail">
            <p>AZUKI</p>
            <p>9.00 Ξ</p>
          </div>
        </div>

        <button
          onClick={() => console.log("Clicked on buy fraction")}
          className="mintNewBtn"
        >
          Buy Fraction
        </button>
      </div>{" "}
    </div>
  );
};

export default Slide2;
