import React, { useState } from "react";
import ExploreBtn from "./ExploreBtn";
import items from "./exploreData";
import ExploreMenu from "./ExploreMenu";
//import ExploreSlider from "./ExploreSlider";
//import { SliderData } from "./ExploreSdata";
import { CoreV0 } from "../Contract/constant";
import { lsCheck, fetchNFTDataPerAccount } from "../FetchApis/FetchNFT";

const allCategories = [
  "All ⚡",
  ...new Set(items.map((item) => item.category)),
];
const getData = (i) => {
  let data;
  lsCheck(localStorage.getItem("explorer"), (e) => {
    let exp = JSON.parse(e);
    if (exp === null) return;
    console.log(exp);
    if (i == "items") data = exp.items;
    else if (i == "slide") data = exp.slide;
    // setMenuItem(exp.items);
    //setSliderData(exp.slide)
  });
  return data;
};
const Explore = () => {
  const [menuItem, setMenuItem] = useState(getData("items"));
  const [buttons, setButtons] = useState(allCategories);
  const [slideData, setSliderData] = useState(getData("slide"));

  const filter = (button) => {
   // alert(button);
   let items = getData("items");
    if (button === "All ⚡") {
      setMenuItem(items);
      return;
    }

    const filterData = items.filter((item) => item.category === button);
    //alert(filterData);
    setMenuItem(filterData);
  };
  /*
  lsCheck(localStorage.getItem("explorer"),(e)=>{ 
    let exp= JSON.parse(e);
    if(exp === null) return;
    console.log(exp.items);
    console.log(exp.slide);
   // setMenuItem(exp.items);
    //setSliderData(exp.slide)
  });*/
  //setMenuItem(getItems());
  /*const getItems = () => {
    //console.log(menuItem.length);
    if (!(menuItem.length < 2)) return;

    const options = { method: "GET" };

    fetch(
      "https://rinkeby-api.opensea.io/api/v1/assets?owner=" +
        CoreV0 +
        "&offset=0&limit=100",
      options
    )
      .then((response) => {
        response.json().then((result) => {
          let items = [];
          let slide = [];
          //console.log(result.assets[0].asset_contract,result.assets[0].token_id,result.assets[0]);
          /*
        {
          id: 0,
          image: "https://media.giphy.com/media/l0Hly6YhCTc1rzP56/giphy.gif",
          title: "REBUILD ECO",
          category: "ECO",
          price: "0.94 ETH ",
          available: " 1 of 1",
          description: "Place a bid",
        }
          result.assets.map((id, i = 0) => {
            let type = true;
            console.log(id, i);
            if (id.image_url.match("mp4")||id.image_url.match("mov")) type = false;
            items.push({
              id: i,
              image: id.image_url,
              title: id.asset_contract.name,
              nft: id.asset_contract.address,
              tknId: id.token_id,
              category: id.asset_contract.address,
              name:id.name,
              creator:id.creator.address,
              type: type
            });
            if (i < 5) slide.push({ image: id.image_url, type: type });
            i++;
          });
          console.log(slide);
          setMenuItem(items);
          setSliderData(slide);
        });
      })
      .catch((err) => console.error(err));
  };*/
  //console.log(slideData,"Hello",getData('slide'));

  return (
    <div className="ex_app">
      {/* <div className="ex_title">
        <h1>
          Explore
          <span className="ex_s">Fusi ⚡</span>
        </h1>
      </div> 
      <ExploreSlider slides={slideData} />*/}
      <ExploreBtn button={buttons} filter={filter} />
      <ExploreMenu menuItem={menuItem} />
    </div>
  );
};

export default Explore;
