import React, { useContext, useState,useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {FTAbi} from "../Contract/abi/abi";
import {Web3Context} from "../Context/Web3context";
import {pingSold} from "../FetchApis/FetchNFT";

const Delist = (props) => {
  const [redeemData, setRedeemData] = useState();
  const state = useContext(Web3Context);
  

  const delist = ()=>{
    console.log(props)
    if(state !== undefined && props.ft !== undefined){
      console.log(props)
    const core = new state.web3.eth.Contract(FTAbi, props.ft);
    core.methods
    .RECPT()
    .call({ from: state.address })
    .then((res) => {
      core.methods.delist(res).send({from:state.address})
      .then(r=>{
        let obj={_id: props.ft,
          key: props.nid.nft+'_'+props.nid.id,
          blockNum: r.blockNumber,
          nft:props.nid.nft,
          tknId:parseInt(props.nid.id),
          FT:props.ft,
          soldAt:0,
          buyer:state.address}

          pingSold(obj).then(r=>{
            console.log(r);
            setRedeemData();
            props.onHide();

          })
      }).catch(err=>{console.log(err,'unable to execute Delist');})
    
    }).catch(err=>{console.log(err,'unable to fetch Receipt');})
    
    
  }
  }
  useEffect(()=>{
  if(state!==undefined && props.ft !== undefined ){
    const core = new state.web3.eth.Contract(FTAbi, props.ft);
    core.methods
    .getRedeemingValues(props.ft)
    .call({ from: state.address })
    .then((result) => {
      //console.log(result);
      const redeem = {
        buyOut: state.web3.utils.fromWei(result.ethValue, "ether"),
        ftReq: parseFloat(state.web3.utils.fromWei(result.tokens, "ether")).toFixed(3),
        poolFlag: result.xyFlag,
      };
      //alert(JSON.stringify(result));
      setRedeemData(redeem);

      //updateNFTData();
    });
  }},[props.ft,redeemData])
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          border: "none",
        }}
      >{redeemData?<>
        <Modal.Header closeButton className="modal_body"></Modal.Header>
        <Modal.Body className="modal_body" style={{textShadow:"none"}}>
        
        {redeemData.poolFlag?
          <div>
            You can Delist your NFT by clicking the button below
          </div>:<div>
            You cannot Delist as {redeemData.ftReq} are already sold.
          </div>}
        
        </Modal.Body>
        <Modal.Footer className="modal_body">
        
        {redeemData.poolFlag?
        <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button" onClick={delist}  variant="primary" type="submit">
          <span class="nft-card-buy-button-text" >  Delist NFT</span>
        </button>:<></>}
        
        </Modal.Footer></>
        :''}
      </Modal>
    </div>
  );
};

export default Delist;
