import React, { useState, useContext, useEffect} from "react";
//import Create from "./Create";
import {Steps,Input,InputNumber,message,Radio } from "antd";
import { Modal, Button, Form } from "react-bootstrap";
import { Ierc721, coreAbi, Ierc20 } from "../Contract/abi/abi";
import { CoreV0,rR } from "../Contract/constant";
import { Web3Context } from "../Context/Web3context";
import Spinner from "react-bootstrap/Spinner";
import cardImg from "../images/image-placeholder-square.png";
import axios from "axios";
import {pingListed} from "../FetchApis/FetchNFT"
import Hint from "./Hint";
import {AiOutlineArrowLeft} from "react-icons/ai"
const { Step } = Steps;

const CreateModal = (props) => {
  const [nftAddr, setNftAddr] = useState("");
  const [nftTknId, setNftTknId] = useState(0);
  const [nftApproved, setNFTApproved] = useState();
  const [ftMinted, setFTMinted] = useState();
  const [ftNum, setFTNum] = useState();
  const [ethReqForLP, setETHReq] = useState(0);
  const [nftSellPrice, setNFTSellPrice] = useState(0);
  const [nftFTDesired, setFTDesired] = useState(0);
  const [recpt, setRecpt] = useState();
  const [listStatus, setListStatus] = useState(false);
  const [num,setNum]=useState(0);
  const [image_url,setImg]=useState(cardImg);
  const state = useContext(Web3Context);
  const [metaData,setMeta] =useState();
  const [nftRoyalty,setRoyalty] = useState();
  
  //const {state } = props;
  //console.log(props.state);
  const resetList = () => {
    setNftAddr("");
    setNftTknId(0);
    setNFTApproved();
    setFTMinted();
    setFTNum();
    setETHReq(0);
    setNFTSellPrice(0);
    setFTDesired(0);
    setMeta();
    setImg(cardImg);
   // init =false;
   // setRecpt();
  };
  //console.log(props.init);
 
  const onInputNFTAddr = (e) => {
    setNftAddr(e.target.value);
  };
  const onInputTknId = (e) => {
    // console.log(state);
    //fetchTokenURI(nftContract,e);
    //setNFTData({contract:nftContract,tokenId:e});
    
    setNftTknId(e);
    if(nftAddr !== undefined)
      getTokenURI(nftAddr,e);
  }
  const onInputSellPrice = (e) => {

      setNFTSellPrice(e);
  
  };
  const onInputAmtDesired = (e) => {
    setFTDesired(ftNum*e.target.value);
    ethRequired(e.target.value);
  };
  const ethRequired = (a) => {
    let ftInETH = nftSellPrice / ftNum;
    setETHReq(nftSellPrice * a);
  };
  const approveNFT = () => {
    if (nftAddr === "" && nftTknId == 0) return;
    if (state != undefined) {
      setListStatus(true);
     // console.log(nftAddr, nftTknId);
      const core = new state.web3.eth.Contract(Ierc721, nftAddr);
      core.methods
        .approve(CoreV0, nftTknId)
        .send({ from: state.address })
        .then((r) => {
         // console.log(r);
          setNFTApproved(true);
          setListStatus(false);
          setNum(1);
        }).catch(e=>{message.error("couldn't approve"); setListStatus(false);});
    }
  };
  const depositNFT = () => {
    if (state != undefined && ftNum > 0) {
      let numFT = state.web3.utils.toWei(ftNum.toString(), "ether");
      //console.log(nftAddr,nftTknId);
      setListStatus(true);
      const core = new state.web3.eth.Contract(coreAbi, CoreV0);
      core.methods
        .depositNFTAndMintTokenFT(nftAddr, nftTknId, numFT)
        .send({ from: state.address })
        .then((r) => {
          //console.log(r.events.NFTLockedAndERCMinted.returnValues.receiptId);
          setRecpt(r.events.NFTLockedAndERCMinted.returnValues.receiptId);
          setFTMinted(true);
          setListStatus(false);
          setNum(2);
        });
    }
  };
  const createLP = () => {
    if (state != undefined && ethReqForLP > 0 && nftFTDesired > 0) {
      setListStatus(true);
      let _value = state.web3.utils.toWei(ethReqForLP.toString(), "ether");
      let _receiptId = recpt;
      let _amountTokenDesired = state.web3.utils.toWei(
        nftFTDesired.toString(),
        "ether"
      );
      //console.log(nftAddr,nftTknId);
      const core = new state.web3.eth.Contract(coreAbi, CoreV0);
      core.methods
        .initUniLPwithETH(_receiptId, _amountTokenDesired, 125000, 0, true)
        .send({ from: state.address, value: _value })
        .then((r) => {
         // console.log(r);
          setListStatus(false);
          //setNFTApproved();
          //resetList();
          
          props.onHide();
          let url =
            window.location.origin + "/app/" + nftAddr + "/" + nftTknId;
          window.location.href = url;
        }).catch((err) => {
          setListStatus(false);

        })
    }
  };
 /* const resetList = () => {
    setNftAddr("");
    setNftTknId(0);
    setNFTApproved();
    setFTMinted();
    setFTNum();
    setETHReq(0);
    setNFTSellPrice(0);
    setFTDesired(0);
    init =false;
   // setRecpt();
  };*/
  const gotoNext = ()=>{
    setFTMinted(true);
  }
  const gotoPrev = ()=>{
    setFTMinted(false);
  }
  const listNFT =()=>{
    if (state !== undefined && ftNum > 0 && ethReqForLP > 0 && nftFTDesired > 0){
      let numFT = state.web3.utils.toWei(ftNum.toString(), "ether");
      let _value = state.web3.utils.toWei(ethReqForLP.toString(), "ether");
      //let _receiptId = recpt;
      let _amountTokenDesired = state.web3.utils.toWei(nftFTDesired.toString(),"ether");
      setListStatus(true);
      //address nft,uint tknId,uint supply,uint _amountTokenDesired)
      const core = new state.web3.eth.Contract(coreAbi, CoreV0);
      core.methods
        .listNFT(nftAddr,nftTknId,numFT, _amountTokenDesired,rR)
        .send({ from: state.address, value: _value })
        .then((r) => {
          setListStatus(false);
          //setNFTApproved();
          //resetList();
          //console.log(r);
          
          let result =r.events.NFTListed.returnValues;
          //console.log(r.blockNumber);
          let obj={_id: result.ListingAddress,
          key: result.NFTConAddress+'_'+result.tokenId,
          blockNum: r.blockNumber,
          nft:result.NFTConAddress,
          tknId:parseInt(result.tokenId),
          FT:result.ListingAddress,
          listedPrice:parseFloat(state.web3.utils.fromWei(result.listingPrice, "ether")).toFixed(3),
          seller:result.seller,
          metadata:metaData?metaData.result:null,
          image:image_url?image_url:null,
          core: CoreV0
        }

          props.onHide();
          pingListed(obj).then(r=>{
            console.log(r);
            let url =
            window.location.origin + "/app/" + nftAddr + "/" + nftTknId;
            window.location.href = url;
          })
          
        }).catch(e=>{message.error("Couldn't list the NFT\nPlease try again."); setListStatus(false);});
    }
  }
  const getTokenURI = (n,t)=>{
    if(state !== undefined && nftAddr !== undefined && nftTknId !== undefined){
    //console.log(n,t);
    const NFT = new state.web3.eth.Contract(Ierc721, n);
    NFT.methods.tokenURI(t).call({from:state.address}).then(r=>{

      getImage(r);
      return;
    })
    }
  }
  const writeMeta = (result)=>{

    result["royalty"] = result["royalty"]?result["royalty"]:0;
    if(result["attributes"] !== undefined){
      result["mimetype"]=result["attributes"].content_type;
    }
    /*else if(result["meta"] !== undefined){

    }*/
    setMeta({result });
  }
  const parseURL= (uri)=> {
    var url = document.createElement("a");
    url.href = uri;
    if (url.protocol === "ipfs:")
      uri = uri.replace("ipfs://", "https://fusible.mypinata.cloud/ipfs/");
    return uri;
  }
  const getImage=(url)=> {
    // url.replace('arweave','sdootys6huhhkhcwzp5tgsobvdapunays3r7irrkcaqagxvshwzq.arweave');
    url = {method:'GET',url:parseURL(url)};
    axios(url).then((result) => {
      
      //alert(JSON.stringify(result));
       let data =result.data;
        
       writeMeta(data);

        var u = parseURL(data["image"]);
        //setType(result["mimetype"].match("image"));
        setImg(u);
     
        
    }).catch(err=>{
      setImg(cardImg);
      
      console.log(err,'NFT not accessible')});

    //return img;
    

  }
  useEffect(()=>{
    if(!props.show){
      resetList();
      return;
    }
    if(state !== undefined){
      console.log(props);
      if(props.n !== undefined && props.t !== undefined){
        console.log('bitch');
      let nftAdd =props.n;
      let tknId = props.t;
      setNftTknId(tknId);
      setNftAddr(nftAdd);
      getTokenURI(nftAdd,tknId);
      //document.getElementById('ncAddr').value= props.n;
      //document.getElementById('ntid').value=props.t;
    }
    }
  },[props.n,props.t,props.type,props.show])

  /*useEffect(()=>{
    if(!props.show){
      resetList();
    }
  },[props.show])*/

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          border: "none",
          backgroundImage: "../images/image-placeholder-square.png",
        }}
        id="listID"
      >
        <Modal.Header className="modal_body" closeButton>
        {ftMinted?<button onClick={gotoPrev} style={{textShadow:'none',fontSize:'large', padding:'1px 4px', border:'none',background:'transparent'}}><AiOutlineArrowLeft/></button>:''}
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal_body">
          {/* Here we place Form Data */}
          {/* <Create /> */}
          {/* <h2 style={{ textAlign: "center", fontSize: "4rem" }}>
            Unlocking Soon
          </h2>
          <h4 style={{ textAlign: "center" }}>
            For creators/sellers to list their NFTs
          </h4> */}
          <Form.Group style={{textShadow:"none"}}>
            {listStatus ? (
               <Steps  current={num}  direction="vertical">
               <Step title="Approve" description="Click on approve and Keep calm till your NFT is being approved"  />
               <Step title="Listing" description="Click on confirm to list your NFT." />
               {/*<Step title="List" description="Please approve the txn" />*/}
              </Steps>

            ) : (
              <>
                {nftApproved ? (
                  <>
                    {/**_receiptId,_amountTokenDesired */}
                    {ftMinted ? (
                      <>
                      <div>
                        <h4 style={{textDecoration:"none",textShadow:'none'}}>Provide initial liquidity</h4>
                        <div></div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                        <div>Number of shards/FTs for LP</div>
                        <div>1 FT = {nftSellPrice/ftNum+" BNB"} </div>
                        </div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                        {/*<InputNumber style={{width:"100%"}}  defaultValue='10'  placeholder="FT for LP"/>*/}
                        
                        <Radio.Group size="small" style={{ marginTop: 16 }}  onChange={onInputAmtDesired} required>
                          
                        <Radio.Button value={0.01}>1%</Radio.Button>
                        <Radio.Button value={0.10}>10%</Radio.Button>
                        <Radio.Button value={0.25}>25%</Radio.Button>
                        <Radio.Button value={0.50}>50%</Radio.Button>
                        <Radio.Button value={0.75}>75%</Radio.Button>
                        </Radio.Group>
                        </div>
                        <span>{''}</span>

                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                          <div>Amt of BNB required for LP</div>
                        </div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                        {ethReqForLP}
                        </div>
                     </div>
                        {/*<Form.Label style={{ fontSize: "32px" }}>
                          Uniswap LP
                        </Form.Label>
                        <br></br>
                        <Form.Label style={{ fontSize: "32px" }}>
                          Add FT:
                        </Form.Label>
                        <Form.Control
                          onChange={onInputAmtDesired}
                          max={ftNum}
                          type="number"
                          placeholder="FT for LP"
                        />
                        <Form.Label style={{ fontSize: "32px" }}>
                          ETH Required :
                        </Form.Label>
                        <br></br>
                        <Form.Label style={{ fontSize: "32px" }}>
                          {ethReqForLP}
                        </Form.Label>*/}
                      </>
                    ) : (
                      <>
                      <div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                        <div>Set the Selling/Reserve Price of the NFT:</div>
                        </div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                        <InputNumber style={{width:"100%"}}onChange={onInputSellPrice} min="0.01"  placeholder="Amount in BNB"/></div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                          <div>Select Number of FT/Shares <Hint
                              text={"Selling price divided into shares"}
                            />:</div>
                        </div>
                        <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                        {/*<InputNumber style={{width:"100%"}} onChange={(e) => {setFTNum(e)}} placeholder="Shrads/FTs"/>*/}
                        <Radio.Group required size="small" style={{ marginTop: 16 }}  onChange={(e) => {setFTNum(e.target.value)}} required>
                        <Radio.Button value={10}>10</Radio.Button>
                        <Radio.Button value={100}>100</Radio.Button>
                        <Radio.Button value={1000}>1000</Radio.Button>
                        <Radio.Button value={10000}>10000</Radio.Button>
                        </Radio.Group>
                        </div>
                       
                     </div>
                      {/*
                        <Form.Label style={{ fontSize: "32px" }}>
                          Selling Price:
                        </Form.Label>
                        <Form.Control
                          onChange={onInputSellPrice}
                          type="number"
                          placeholder="NFT Selling price"
                        />
                        <Form.Label style={{ fontSize: "32px" }}>
                          Number of FT :{" "}
                        </Form.Label>
                        <Form.Control
                          onChange={(e) => {setFTNum(e.target.value);
                          }}
                          type="number"
                          placeholder="Fractions"
                        />*/}
                      </>
                    )}
                  </>
                ) : (
                  <>
                     <div>
                     <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none'}}>
                       <div>NFT Address</div>
                       <div>Token Id</div>
                     </div>
                     {props.type?
                     <div className="ant-row ant-row-space-between create-bottom-row"
                     style={{ rowGap: "0px", border:"2px solid #3498db",marginBottom: "10px",borderRadius:"12px",padding:"10px" }}>
                     <div class="ant-col-16"><Input  id="ncAddr" value={props.n}  placeholder=" NFT Contract Address" disabled /></div>
                     <div> <InputNumber id="ntid"  value={props.t} placeholder="token Id"  disabled /> </div>
                    </div>
                     
                     :
                    <div className="ant-row ant-row-space-between create-bottom-row"
                      style={{ rowGap: "0px", border:"2px solid #3498db",marginBottom: "10px",borderRadius:"12px",padding:"10px" }}>
                      <div class="ant-col-16"><Input  id="ncAddr" onChange={onInputNFTAddr}  placeholder=" NFT Contract Address" /></div>
                      <div> <InputNumber id="ntid"  onChange={onInputTknId} placeholder="token Id"   /> </div>
                    </div>}
                    <div className="ant-row ant-row-space-between create-bottom-row" style={{textDecoration:"none",textShadow:'none',height:'70px'}}>
                    {metaData?
                    <>
                      <div>
                       
                       {metaData.result["creators"]?<>
                       <div className="ant-row" >Creator:</div>
                       <div className="ant-row" >
                        {JSON.parse(metaData.result["creators"]).map(
                              (c) => {
                                return (
                                  <>
                                    <b className="p_desc" style={{margin:"0 2px 0 2px"}}>
                                      {c.address.substring(0, 5) + " "}
                                    </b>
                                  </>
                                );
                              }
                            )}
                            </div>
                       </>
                       :<>  <div className="ant-row" >Name:</div>
                       <div className="ant-row" >{metaData.result["name"]?metaData.result["name"]:''}</div></>} 

                       
                      {metaData.result["royalty"]?<>
                       <div className="ant-row" >Royalty Fee:</div>
                       <div className="ant-row" >
                      { metaData.result["royalty"].toString() + "%"}</div>
                      </>:
                      <>  </>}
                       
                      </div>
                         <div style={{width:"30%",height:"100%", justifyItems:'center'}}>
                         
                          { metaData.result["mimetype"]?
                          metaData.result["mimetype"].match("image")?
                           <img style={{width:"100px",height:"100px",objectFit:'cover'}}
                            src={image_url}/>:
                            <video
                             src={image_url}
                             preload={"auto"}
                             /*autoPlay={true}
                             loop={true}*/
                             playsInline=""
                             webkit-playsinline=""
                             style={{width:"100px",height:"100px"}}
                             muted controls
                           ></video>:
                           <img style={{width:"100px",height:"100px"}}
                           src={image_url}/>}
                            
                            </div>
                      
                      </>
                      :<>
                        
                      </>}
                   
                       
                     </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="modal_body">
          {listStatus ? (
            ""
          ) : (
            <>
              {nftApproved ? (
                <>
                  {ftMinted ? (
                    <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"  onClick={listNFT} variant="primary" type="submit">
                      <span class="nft-card-buy-button-text" >  List NFT</span>
                    </button>
                  ) : (
                    <>
                    {(nftSellPrice>0&&ftNum>0)?
                      <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button" 
                        onClick={gotoNext}
                        variant="primary"
                        type="submit"
                      >
                         <span class="nft-card-buy-button-text" > Next</span>
                      </button>:''}
                    </>
                  )}
                </>
              ) : (
                (metaData)?
                <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"  onClick={approveNFT} variant="primary" type="submit">
                  <span class="nft-card-buy-button-text" > Approve NFT</span> 
                </button>:
                 <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button"  variant="primary" type="submit" disabled>
                 <span class="nft-card-buy-button-text" > Loading MetaData</span> 
               </button>
              )}
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateModal;
