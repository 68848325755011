/* eslint-disable import/no-anonymous-default-export */
export default [
 
  {
    id: 0,
    category: "Video",
  },
  {
    id: 1,
    category: "Image",
  }
];
