import axios from "axios";
import {baseURL} from '../Contract/constant';

export const fetchExplore =()=>{
  const config = {method: 'GET', url : `${baseURL}/api/explore`};
  return axios(config);     
        
}
export const pingListed =async (data)=>{
  if(data=== undefined) return null;
  const config = {method: 'POST', url : `${baseURL}/api/listed`,data:data};
  return axios(config); 
  console.log('pinged Listed') 
}
export const pingSold =async(data)=>{
  if(data=== undefined) return null;
  const config = {method: 'POST', url : `${baseURL}/api/sold`,data:data};
   return axios(config);  
   console.log('pinged Sold')
}
export const fetchFT =(address)=>{
  const config = {method: 'GET', url : `${baseURL}/api/myFT?address=${address}`};
  return axios(config);     
        
}
export const fetchNData =(key)=>{
  const config = {method: 'GET', url : `${baseURL}/api/ndata?key=${key}`};
  return axios(config);   
}
export const fetchOnSale =(address)=>{
  const config = {method: 'GET', url : `${baseURL}/api/onSale?address=${address}`};
  return axios(config);   
}
export const fetchNFTDataPerAccount =  (account,callback)=>{
    const config = {method: 'GET',url:`${baseURL}/api/myNFT?address=${account}`};
    
    axios(config)
      .then((response) => {
        
          /*let items =[];
          let slide =[];
          let nft =response.data['data']['items'][0];
          let tokenURL = nft.nft_data[0].token_url.replace('https://cloudflare-ipfs.com/','https://ipfs.io/ipfs/');
         // console.log(tokenURL);
          const a = axios.get(tokenURL);

          //console.log(result.assets[0].asset_contract,result.assets[0].token_id,result.assets[0]);
          /*
          {
            id: 0,
            image: "https://media.giphy.com/media/l0Hly6YhCTc1rzP56/giphy.gif",
            title: "REBUILD ECO",
            category: "ECO",
            price: "0.94 ETH ",
            available: " 1 of 1",
            description: "Place a bid",
          }*/
          callback(response.data );
          /*
          result.assets.map((id,i=0)=>{
            let type=true;
            console.log(id,i);
            if(id.image_url.match('mp4'))
              type=false;
            items.push({id:i,image:id.image_url,title:id.asset_contract.name,nft:id.asset_contract.address,tknId:id.token_id, category: id.asset_contract.address,type:type});
            if(i<5)
              slide.push({image:id.image_url,type:type});
            i++;
          });
         console.log(slide);
           ls.setItem('items',items);
           ls.setItem('slide'slide);
          */
  
    
      
    })
      .catch(err => console.error(err))
    
  };
export const lsCheck=(k,callback)=>{
    //console.log(k);
      callback(k);
  }

  