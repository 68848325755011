import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Home from "./components/Home";
import Explore from "./components/Explore";
import AppV0 from "./components/AppV0";
import Navbar from "./components/Navbar";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Profile from "./components/Profile";
import Onsale from "./components/Onsale";
import Nfts from "./components/Nfts";
import Myfts from "./components/Myfts";
import User from "./components/User";
import { Web3Context } from "./Context/Web3context";
import MintNFT from "./components/MintNFT";
import CreateNFT from "./components/createNFTs";
// import { CoreV0, Mint } from "./Contract/constant";
import {
  lsCheck,
  // fetchNFTDataPerAccount,
  fetchExplore,
} from "./FetchApis/FetchNFT";
import EditProfile from "./components/EditProfile";
import FAQ from "./components/FAQ";
//import {RINKEBY,MATIC} from "./Contract/constant";
import { NetId } from "./Contract/constant";
// import { AssessmentTwoTone } from "@material-ui/icons";

const ls = localStorage || window.localStorage;
let oranges = true;
const checkIfActiveUser = () => {
  if (oranges) {
    let timeNow = Math.floor(new Date().getTime() / 1000);
    console.log(
      timeNow - parseFloat(ls.getItem("sessionStart")) < 60 * 60,
      timeNow - parseFloat(ls.getItem("sessionStart"))
    );
    let check =
      ls.getItem("sessionStart") !== null
        ? timeNow - parseFloat(ls.getItem("sessionStart")) < 60 * 60
        : false;
    //oranges = check;
    oranges = !check;
    return check;
  } else return false;
};

const checkPoint = (lastUpdated) => {};
function App() {
  const [state, setState] = useState();

  lsCheck(ls.getItem("explorer"), (e) => {
    let exp;

    let items = [];
    let slide = [];
    let timeNow = Math.floor(new Date().getTime() / 1000);
    exp = JSON.parse(e);

    //if (e !== null && timeNow - exp.lastUpdated < 5) return;
    /*fetchNFTDataPerAccount(CoreV0, (result) => {
      console.log(result);
      result.assets.map((id, i = 0) => {
        let type = true;
        //console.log(id,i);
        if (id.image_url.match("mp4") || id.image_url.match("mov"))
          type = false;
        items.push({
          id: i,
          image: id.image_url,
          title: id.asset_contract.name,
          nft: id.asset_contract.address,
          tknId: id.token_id,
          category: (type?'Image':'Video'),
          name: id.name,
          creator: id.creator.address,
          type: type,
        });
        if (i < 5) slide.push({ image: id.image_url, type: type });
        i++;
      });
      //console.log(slide);
      let explorer = {
        items: items,
        slide: slide,
        lastUpdated: Math.floor(new Date().getTime() / 1000),
      };
      explorer = JSON.stringify(explorer);
      console.log(explorer);
      ls.setItem("explorer", explorer);
    });*/

    fetchExplore().then((response) => {
      let explorer = {
        items: JSON.parse(response.data),
        slide: slide,
        lastUpdated: Math.floor(new Date().getTime() / 1000),
      };
      explorer = JSON.stringify(explorer);
      console.log(explorer);
      ls.setItem("explorer", explorer);
    });
  });

  //console.log(explor);
  async function init() {
    console.log("starting up");
    //console.log("WalletConnectProvider is", WalletConnectProvider);
    //console.log("Fortmatic is", Fortmatic);

    const providerOptions = {
      metamask: {},
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            56: "https://bsc-dataseed.binance.org/",
          },
          network: "binance",
          chainId: 56,
          // infuraId: '5b6b8cd2731e4a26a526dc505322712d',
        },
      },
    };

    const web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: false, // optional
      //disableInjectedProvider: false,
      providerOptions,
      theme: "dark", // required
    });

    const provider = await web3Modal.connect();
    //console.log(provider);

    const web3 = new Web3(provider);
    //console.log(modal)

    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    const networkId = await web3.eth.net.getId();
    if (networkId !== 56) {
      //console.log(window.ethereum.isMetaMask);
      //  alert(JSON.stringify(NetId.BSC_TEST[0].chainId));

      if (window.ethereum.isMetaMask) {
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: NetId.BSC,
          })
          .then((decryptedMessage) => {
            init();
            console.log("The decrypted message is:", decryptedMessage);
          })
          .catch((error) => console.log(error.message));
      }
      web3Modal.clearCachedProvider();
      web3Modal.resetState();
      return;
    }
    const balance = await web3.eth.getBalance(address);
    if (oranges) {
      //console.log('ok');
      ls.setItem(
        "sessionStart",
        Math.floor(new Date().getTime() / 1000).toString()
      );
      oranges = false;
    }
    console.log(address);
    console.log(balance);

    /**/
    //  console.log(networkId)
    setState({
      web3,
      provider,
      connected: true,
      address,
      balance,
      web3Modal,
      networkId,
    });
    // window.provider = provider;
    await subscribeProvider(provider);
  }

  const subscribeProvider = async (provider) => {
    if (!provider.on) {
      return;
    }
    // provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts) => {
      //if(state !== undefined && state.web3 !== undefined)
      //  console.log(state);
      //setProviderData({ address: accounts[0] });
      //setState({ address: accounts[0] });
      init();
    });
    provider.on("chainChanged", async (chainId) => {
      // const { web3 } = this.state;
      //const networkId = await web3.eth.net.getId();
      // setState({ chainId, networkId });
      //setProviderData({ chainId, networkId });
      init();
    });
    provider.on("networkChanged", async (networkId) => {
      //const { web3 } = this.state;
      //const chainId = await web3.eth.chainId();
      //  await this.setState({ chainId, networkId });
      //setProviderData({ chainId, networkId });
      init();
    });
    provider.on("open", () => {
      console.log("open");
    });

    // Subscribe to session disconnection/close
    provider.on("close", (code, reason) => {
      console.log(code, reason);
    });
  };

  const onConnect = async () => {
    try {
      await init();
    } catch (err) {
      console.log(err);
      alert("failed to connect");
    }
  };

  const logout = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  if (checkIfActiveUser()) onConnect();
  //if(fetchAndUpdateExp)
  // onConnect();
  //console.log(check)

  // setActiveUser(false);
  /*
 lsCheck(ls["myNFT"],(e)=>{
  let exp = JSON.parse(e);
  let items=[];let slide =[];
  let timeNow = Math.floor(new Date().getTime()/1000);
  if(e !== null &&(timeNow-exp.lastUpdated) < 90) return;
  if(state.address!== undefined) return;
  fetchNFTDataPerAccount(state.address,(result)=>{
    //console.log(result);
    result.assets.map((id,i=0)=>{
      let type=true;
      //console.log(id,i);
      if(id.image_url.match('mp4'))
        type=false;
      items.push({id:i,image:id.image_url,title:id.asset_contract.name,nft:id.asset_contract.address,tknId:id.token_id, category: id.asset_contract.address,type:type});
   
    });
   //console.log(slide);
    let explorer = {'items':items,'lastUpdated':Math.floor(new Date().getTime()/1000)};
    explorer=JSON.stringify(explorer);
    console.log(explorer);
    ls.setItem('myNFT',explorer)
    
  });   

});

*/
  return (
    <div>
      <Web3Context.Provider value={state}>
        <Navbar onConnect={onConnect} logout={logout} />
        {/* <Header
        web3State={state ? state : false}
        onConnect={onConnect}
        logout={logout}
      /> */}
        <Switch>
          {/* This Route redirect to Home Page */}
          <Route exact path="/" component={Home} />
          <Route exact path="/explore" component={Explore} />
          <Route exact path="/app/:nft/:id" component={AppV0} />
          <Route exact path="/app/:core/:nft/:id" component={AppV0} />
          <Route exact path="/mintnft" component={MintNFT} />
          <Route exact path="/createnft" component={CreateNFT} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/user/:address" component={User} />
          <Route exact path="/editprofile" component={EditProfile} />
          <Route exact path="/profile/onsale" component={Onsale} />
          <Route exact path="/profile/mynfts" component={Nfts} />
          <Route exact path="/profile/myfts" component={Myfts} />
          <Route exact path="/faq" component={FAQ} />

          {/* This line help us to deal with wrong route link */}
          <Redirect to="/" />
        </Switch>
      </Web3Context.Provider>
    </div>
  );
}

export default App;
