import React from "react";
import { Tooltip } from "antd";
// import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";
import { BsQuestionCircle } from "react-icons/bs";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "aliceblue",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontFamily: "Helvetica",
    fontWeight: "Bold",
    borderRadius: "15px",
    border: "1px solid lightgrey",
    padding: "10px",
  },
}))(Tooltip);

const Hint = (props) => {
  return (
    <>
      <Tooltip
        placement="bottom"
        title={props.text}
        style={{ backgroundColor: "aliceblue", color: "#000" }}
      >
        <BsQuestionCircle style={{ position: "relative", top: "-2px" }} />
      </Tooltip>
      {/* <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={props.text}
        arrow
      ></Tooltip> */}
    </>
  );
};

export default Hint;
