import React from "react";
import "./Footer.css";
import FusiiLogo from "../images/fusii-logo.png";
import { Row, Col } from "antd";
import { IoLogoTwitter } from "react-icons/io";
import { FaTelegram, FaDiscord } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="footer">
      <Row>
        <Col xs={2} sm={4} md={6} lg={8} xl={10}>
          <div style={{ display: "flex" }}>
            <img src={FusiiLogo} alt="FusiiLogo" className="footer-logo" />
            <p style={{ margin: "0" }} className="footer-txt">
              Created with ❤ for Fusii
            </p>
          </div>
        </Col>
        <Col xs={2} sm={4} md={6} lg={8} xl={10}></Col>
        <Col xs={20} sm={16} md={12} lg={8} xl={4}>
          <div className="footer-iconWrapper">
            <a
              href="https://t.me/fusibleio_Ann"
              target="_blank"
              className="footer-icons"
              rel="noreferrer"
            >
              <FaTelegram size={24} />
            </a>
            <a
              href="https://twitter.com/fusibleio"
              target="_blank"
              className="footer-icons"
              rel="noreferrer"
            >
              <IoLogoTwitter size={24} />
            </a>
            <a
              href="https://discord.gg/c7827ZqrDk"
              target="_blank"
              className="footer-icons"
              rel="noreferrer"
            >
              <FaDiscord size={24} />
            </a>
            <a
              href="https://www.instagram.com/fusible_io/"
              target="_blank"
              className="footer-icons"
              rel="noreferrer"
            >
              <AiFillInstagram size={26} />
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
