import React from "react";
import { NavLink } from "react-router-dom";

const Navtab = () => {
  return (
    <div>
      <div className="dlSpeG iQUCaz" style={{ justifyContent: "center" }}>
        <NavLink
          activeClassName="bvKcKO"
          exact
          to="/profile/onsale"
          style={{ textDecoration: "none" }}
        >
          <button type="button" className="erMVgR dJLimD">
            <span className="eqDhyJ eSHvkk">On sale</span>
          </button>
        </NavLink>

        <NavLink
          activeClassName="bvKcKO"
          exact
          to="/profile/mynfts"
          style={{ textDecoration: "none" }}
        >
          <button type="button" className="erMVgR dJLimD">
            <span className="eqDhyJ eSHvkk">My NFTs</span>
          </button>
        </NavLink>

        <NavLink
          activeClassName="bvKcKO"
          exact
          to="/profile/myfts"
          style={{ textDecoration: "none" }}
        >
          <button type="button" className="erMVgR dJLimD">
            <span className="eqDhyJ eSHvkk">My FTs</span>
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default Navtab;
