import React, { useState, useRef, useContext, useEffect } from "react";
import { Input } from "antd";
import { InputNumber } from "antd";
import { NFTSearchData } from "../Context/NFTSearchData";
import { Web3Context } from "../Context/Web3context";
import { Ierc721, coreAbi, FTAbi } from "../Contract/abi/abi";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchbarDropdown = (props) => {
  const { options, onInputChange } = props;
  const ulRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.addEventListener("click", (event) => {
      event.stopPropagation();
      ulRef.current.style.display = "flex";
      onInputChange(event);
    });
    document.addEventListener("results", (event) => {
      ulRef.current.style.display = "none";
    });
  }, []);

  return (
    <div className="search-bar-dropdown">
      <input
        id="search-bar"
        type="text"
        className="search"
        placeholder="Add NFT FRC20 address"
        ref={inputRef}
        onChange={onInputChange}
      />
      <ul id="results" className="list-group" ref={ulRef}>
        {options.map((option, index) => {
          return (
            <button
              type="button"
              key={index}
              onClick={(e) => {
                inputRef.current.value = option;
              }}
              className="list-group-item list-group-item-action"
            >
              {option}
            </button>
          );
        })}
      </ul>
    </div>
  );
};

const defaultOptions = [];
defaultOptions.push(
  "Ethereum",
  "Wrapped Ethereum",
  "Chainlink",
  "USD Coin",
  "Uniswap"
);

const Search = ({ nft, tknId ,CoreV0}) => {
  // const [nftErc20Address, setNFTERC20Addr] = useState("");

  // const submitAddr = () => {};
  // const inputEvent = (e) => {
  //   console.log(e.target.value);
  //   setNFTERC20Addr(e.target.value);
  // };
  const state = useContext(Web3Context);
  const setRecData = useContext(NFTSearchData);
  const setTokenURI = useContext(NFTSearchData);
  const [nftContract, setNftAddr] = useState();
  const [nftTid, setNftTid] = useState();
  const [nftPrice, setNftPrice] = useState({});
  const [reSelFT, setReSelFT] = useState({});
  const [options, setOptions] = useState([]);
  const query = useQuery();
  /*
  const onInputChange = (event) => {
    setOptions(
      defaultOptions.filter((option) =>
        option.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };*/
  //console.log(setNFTData);
  //const core = new state.web3.eth.Contract(coreAbi,CoreV0);
  const onInputNFTAddr = (e) => {
    setNftAddr(e.target.value);
  };
  const onInputTknId = (e) => {
    //console.log(nftContract);
    setNftTid(e);
    fetchTokenURI(nftContract, e);
    //setNFTData({contract:nftContract,tokenId:e});
  };
  function fetchTokenURI(n, t) {
    let receipt;
    const core = new state.web3.eth.Contract(coreAbi, CoreV0);
    core.methods
      .getListedAddress(n, t)
      .call({ from: state.address })
      .then((result) => {
        let ftToken = result;
        //alert(ftAddress);
        //setFT(ftAddress);
        const FT = new state.web3.eth.Contract(FTAbi, result);
        FT.methods
          .RECPT()
          .call({ from: state.address })
          .then((res) => {
            receipt = res;

            if (state.web3.utils.hexToNumberString(receipt) === "0") {
              setRecData({ n, t });
              return;
            }
            //getReceiptData(receipt);
            //setURI(n,t);
            //alert(n,t,ftAddress);
            setRecData({ receipt, n, t, ftToken });
          })
          .catch((error) => {
            //alert("RECEIPT NOT ACCESSIBLE.")
            setRecData({ n, t });
          });
        //receipt = result;

        //receipt = state.web3.utils.hexToNumberString(receipt);
      })
      .catch((err) => {
        alert(err);
      });
  }
  /*
 const setURI = (n,t)=>{
  const ERC721 = new state.web3.eth.Contract(Ierc721,n);
  ERC721.methods.tokenURI(t).call({from: state.address}).then(result=>{
    console.log(n,t);
    getImage(result);
  })
 }
 const getReceiptData = (r)=>{
  core.methods.getReceiptData(r).call({from:state.address}).then(result=>{
    const rSFT={ seller:result.seller,
     ftToken :result.mintedERC20Addr}
    //console.log(result.mintedERC20Addr);
    getCurveData(rSFT.ftToken);
    setReSelFT(rSFT);


  })

 }
 const getCurveData =(ft)=>{
   core.methods.getCurve(ft).call({from:state.address}).then(result=>{
    const curve={pBal :result.poolBalanceReserve,
    rR : result.rRatio,
    lPrice: result.lastPrice,
    tSupply: result.totalSupply,
    fPrice: result.floorPrice }

    //console.log(curve);
    setCurveData(curve);

   })
   core.methods.getRedeemingValues(ft).call({from:state.address}).then(result=>{
     //console.log(result);
     const redeem = {buyOut:result.ethValue,
     ftReq:result.tokens,
     poolFlag:result.xyFlag}

     setNftPrice(redeem);

     updateNFTData();
   })

 }

 function getImage(url){
  // url.replace('arweave','sdootys6huhhkhcwzp5tgsobvdapunays3r7irrkcaqagxvshwzq.arweave');
   fetch(url).then(res=>{res.json().then(result=>{
     setTokenURI(result['image']);
    })});
   
   //return img;
 }*/

  useEffect(() => {
    //alert(JSON.stringify(nft,tknId));
    if (state !== undefined) {
      //console.log(query.has("nft"), query.has('tknId'));
      if (nft !== undefined && tknId !== undefined) {
        //Todo Validate string
        //let nftAdd = query.get('nft');
        //let tknId = query.get('tknId');
        setNftTid(tknId);
        setNftAddr(nft);
        document.getElementById("ncAddr").value = nft;
        document.getElementById("ntid").value = tknId;
        fetchTokenURI(nft, tknId);
      }
    }
  }, [state]);
  return (
    <div hidden>
      <div
        className="ant-row ant-row-space-between create-bottom-row"
        style={{
          rowGap: "0px",
          border: "2px solid #3498db",
          marginBottom: "10px",
          borderRadius: "12px",
          padding: "10px",
        }}
      >
        <div class="ant-col-16">
          <Input
            id="ncAddr"
            onChange={onInputNFTAddr}
            value={nftContract}
            placeholder=" NFT Contract Address"
          />
        </div>
        <div>
          {" "}
          <InputNumber
            id="ntid"
            value={nftTid}
            onChange={onInputTknId}
            placeholder="token Id"
          />{" "}
        </div>
      </div>
    </div>
  );
};

export default Search;
