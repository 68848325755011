// export const CoreV0 = "0x0A7eE863b107B67ef358a54b0eC3D39a8304A600";
//export const CoreV0 = "0x3BA2d3e0c569381DA768481d6aD92e58a082B1F4";//Rinkeby
//export const CoreV0 = "0x8d083F6B60D0fDDeF3cd3e218C24BA7966cf3916";//BSC TEST OLD
//<<<<<<< HEAD
//export const CoreV0 = "0xe9C72B87e747F3D640129121307752eCf2c42C1d";
//=======
//export const CoreV0 = "0xe9C72B87e747F3D640129121307752eCf2c42C1d";//BSSC TEST 01-07 old
//export const CoreV0 = "0xc1bddaB83D18f2ca5d4145Db1d532e165928adcd";
//>>>>>>> fe6586c306150e842806e0455db6ee3a16d068a3
//export const FT = "";
//export const Mint = "0x1b14124bA09837e5B96acF44674eCa924147F769";
//export const Mint = "0xe496e6817fe4E7587ACbBD0083F7d2E0A9f4C3A1";//Rinkeby
//export const Mint = "0x4346B538762E1cA7E003913871e943C1A7D14D23";//BSC_TEST
export const MINTER_ROLE ="0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6";
/*Mainnet 56*/
//export const CoreV0 ='0x92Fff7fb04896a1f95A216971630c17F6E2BA0CC';
//export const CoreV0 = '0x13ddD952BBA29DA324AF0C9d93CfD36c38f94Dde'; //Mainnet BSC CoreV0
//export const Mint = "0x10B1Dee28962D321c15C0cbCa651B51032F69d72";//BSC_Main
export const Mint = "0x7E94950b452797Ad046A9Bae4Fe9C42d75548741";
export const CoreV0 = "0x7C1368ab0A1A89503925ADb67279D64608646710";
export const MintFee = 1000000000000000;
//export const Mint = "0x7E94950b452797Ad046A9Bae4Fe9C42d75548741";
export const rR = 1000000;
/* Network Chain Id details */
export const NetId = {
    "BSC":[{"chainId":"0x38","chainName":"BSC Mainnet","rpcUrls":["https://bsc-dataseed.binance.org/","https://bsc-dataseed1.defibit.io/","https://bsc-dataseed1.ninicoin.io/"],"nativeCurrency":{"name":"BNB","symbol":"BNB","decimals":18},"blockExplorerUrls":["https://bscscan.com"]}],
    "BSC_TEST":[{"chainId":"0x61","chainName":"BSC Testnet","rpcUrls":["https://data-seed-prebsc-1-s1.binance.org:8545/"],"nativeCurrency":{"name":"BNB","symbol":"BNB","decimals":18},"blockExplorerUrls":["https://testnet.bscscan.com"]}],
    "MATIC":[{"chainId":"0x89","chainName":"Matic Mainnet","rpcUrls":["https://rpc-mainnet.maticvigil.com/"],"nativeCurrency":{"name":"Matic","symbol":"MATIC","decimals":18},"blockExplorerUrls":["https://explorer.matic.network/"]}]};
/*export const RINKEBY = [{"chainId": "0x4","rpcUrls":["https://rinkeby.infura.io/v3/undefined"]}];
export const MATIC = [{"chainId":"0x89","chainName":"Matic Mainnet","rpcUrls":["https://rpc-mainnet.maticvigil.com/"],
"nativeCurrency":{"name":"Matic","symbol":"MATIC","decimals":18},"blockExplorerUrls":["https://explorer.matic.network/"]}];
export const BSC_TEST = [{"chainId":"0x89","chainName":"BSC Testnet","rpcUrls":["https://data-seed-prebsc-1-s1.binance.org:8545/"],
"nativeCurrency":{"name":"BNB","symbol":"BNB","decimals":18},"blockExplorerUrls":["https://testnet.bscscan.com"]}]*/
/**Base url */
export const baseURL = 'https://api.fusible.xyz';
//export const baseURL = 'http://localhost:3001';
/* NFT Contracts meta */
export const NFTMeta ={
    "0xf5db804101d8600c26598a1ba465166c33cdaa4b":{mould:(result)=>{result=result.nft;let obj={creators:JSON.stringify([{address:result["ownerAddress"]}]),name:result['name'],editions:undefined,image:result['image'],description:result['description'],royalty:undefined,mimetype:result['fileType']?result['fileType']:undefined};return obj;},contract:'AirNFT',type:'ERC721'},

}
