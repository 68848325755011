import React from "react";
import { Switch } from "antd";

const Toggle = ({ checked, onChange }) => (
  <span className="toggle-control">
    <Switch
      size="small"
      className="dmcheck"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      id="dmcheck"
    />
    {/* <input />
    <label htmlFor="dmcheck" /> */}
  </span>
);

export default Toggle;
