import React from "react";

import nftSingle from "../images/nft-card.svg";
import nftCollection from "../images/nft-card-collectio.svg";

const createNFTs = () => {
  return (
    <div className="crnft">
      <h1 className="p_desc crnft-h1">Create NFTs Collection</h1>
      <p className="crnftp">
        Choose 'Mint NFT' if you want your NFT to be one of a kind or <br />
        'Mint Collection' if you want to sell multiple times.
      </p>
      <div className="row">
        <div className="column">
          <div className="crnft-card">
            <div style={{ margin: "25px" }}>
              <img src={nftSingle} alt="nftSingle" className="crnft-image" />
            </div>
            <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button">
              <span class="nft-card-buy-button-text">Mint your single NFT</span>
            </button>
          </div>
        </div>
        <div className="column">
          <div className="crnft-card">
            <div style={{ margin: "25px" }}>
              <img
                src={nftCollection}
                alt="nftCollection"
                className="crnft-image"
              />
            </div>
            <button class="ant-btn ant-btn-primary ant-btn-block nft-card-buy-button">
              <span class="nft-card-buy-button-text">
                Create your own Collections
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default createNFTs;
