import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import CreateModal from "./CreateModal";

const MobModal = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          border: "none",
        }}
      >
        <Modal.Header closeButton className="modal_body"></Modal.Header>
        <Modal.Body className="modal_body">
          <div className="mb-2" style={{ textAlign: "center" }}>
            <Button
              onClick={() => setModalShow(true)}
              variant="outline-primary"
              size="lg"
              style={{ margin: "25px" }}
            >
              <span
            
            style={{ color: "#000", textDecoration: "none", margin: "5px 8px" }}
          >
              List NFT</span>
            </Button>
            <br />
            <Button
              variant="outline-primary"
              size="lg"
              style={{ margin: "25px" }}
              onClick={() =>{ setModalShow(false); let url =
                window.location.origin + "/mintnft";
              window.location.href = url;}}
            >
              <span
            
                style={{ color: "#000", textDecoration: "none", margin: "5px 8px" }}
              >
                Mint NFT
              </span>
            </Button>
          </div>
          <CreateModal show={modalShow} onHide={() => setModalShow(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MobModal;
