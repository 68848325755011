import React from "react";
import AzukiAvatar from "../../images/azuki_img.png";

const Slide3 = () => {
  return (
    <div>
      <div>
        <div style={{ marginBottom: 20 }}>
          <div className="imgDivCard">
            <img src={AzukiAvatar} alt="AzukiAvatar" />
            <div
              style={{
                display: "block",
                margin: "0 auto",
                color: "#FF1D25",
              }}
            >
              <p>8.90 Ξ</p>
              <p>8.90 Ξ</p>
            </div>
          </div>
          <div className="cardMidText">
            <p>AZUKI</p>
            <p>9.00 Ξ</p>
          </div>
          <div className="imgDivCard">
            <img src={AzukiAvatar} alt="AzukiAvatar" />
            <div
              style={{
                display: "block",
                margin: "0 auto",
                color: "#3B9BDF",
              }}
            >
              <p>8.90 Ξ</p>
              <p>8.90 Ξ</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => console.log("Clicked on trade")}
          className="mintNewBtn"
        >
          Trade
        </button>
      </div>
    </div>
  );
};

export default Slide3;
