import React, { useState } from "react";
import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as ExploreIcon } from "../icons/compass-with-white-needles.svg";
import { NavLink } from "react-router-dom";
import { BsPlusSquareFill } from "react-icons/bs";
import MobModal from "./MobModal";

const BtmNav = (props) => {
  const [mobShow, setMobShow] = useState(false);

  return (
    <>
      <div className="btm-nav">
        <div className={props.web3State ? "btm-btns":"btm-btns-3"}>
          <NavLink activeClassName="hm_active" exact to="/" className="nav-btn">
            <HomeIcon
              style={{ width: 20, height: 20, fill: "rgb(39, 117, 219)" }}
            />
          </NavLink>
          <NavLink
            activeClassName="hm_active"
            exact
            to="/explore"
            className="nav-btn"
          >
            <ExploreIcon
              style={{ width: 20, height: 20, fill: "rgb(39, 117, 219)" }}
            />
          </NavLink>

          {props.web3State ? (
            <>
              {/* <button
                className="nav-btn lo-btn log-btn"
                onClick={(e) => {
                  // setToggle(!(toggle));
                }}
              >
                {props.web3State.address.substring(0, 5)}
              </button> */}
                <div className="nav-rc">
                <BsPlusSquareFill
                  onClick={() => setMobShow(true)}
                  style={{
                    width: 20,
                    height: 20,
                    fill: "rgb(39, 117, 219)",
                    margin: "20px",
                  }}
                />
                <MobModal show={mobShow} onHide={() => setMobShow(false)} />
              </div>
              <NavLink
                activeClassName="hm_active"
                exact
                to="/profile"
                className="nav-btn"
              >
                <button
                  className="btn-icon btn-icon1"
                  style={{
                    width: 20,
                    height: 20,
                    fill: "rgb(39, 117, 219)",
                  }}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 10C9.65685 10 11 8.65685 11 7C11 5.34315 9.65685 4 8 4C6.34315 4 5 5.34315 5 7C5 8.65685 6.34315 10 8 10Z"
                      fill="rgb(39, 117, 219)"
                    ></path>
                    <path
                      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12 12.4C11.4 11.7 10.6 11 9 11H7C5.4 11 4.6 11.7 4 12.4C2.8 11.3 2 9.8 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 9.8 13.2 11.3 12 12.4Z"
                      fill="rgb(39, 117, 219)"
                    ></path>
                  </svg>
                </button>
              </NavLink>
            </>
          ) : (
            <>
              <button
                className="nav-btn lo-btn log-btn"
                onClick={props.onConnect}
              >
                Connect Wallet
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BtmNav;
