import React, { useState,useEffect,useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import {Widget, unloadWidget} from 'binance-fiat-widget'

    
const BinanceWidget = () => {
  const r = useRef(null)
  const options = {
    locale: "en",
    theme: "light",
    coinInfo: {
      fiat: 'USD',
      crypto: 'BNB',
      isInUS: false
    },
    urlParmas: {
        ref: 'QARO28G4',
        us_ref: '',
        utm_source: 'app.fusible.io'
    },

  }

  useEffect(() => {
    if (typeof window !== undefined) {
      if (r?.current) {
        Widget(r.current, options)
      }

      return () => {
        unloadWidget()
      }
    }
  }, [r])

  return <div ref={r}></div>
}
const BuyBNB = (props) => {
  const [modalShow, setModalShow] = useState(false);


  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          border: "none",
        }}
      >
        <Modal.Header closeButton className="modal_body"></Modal.Header>
        <Modal.Body className="modal_body" style={{textShadow:'none'}} >
          <BinanceWidget/>

       
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BuyBNB;
