import React from "react";
import "./FAQ.css";

const FAQ = () => {
  return (
    <>
      <div className="my-5 mx-5">
        <div className="text-center mb-5">
          <span className="text-secondary text-uppercase">Questions</span>
          <h1 className="text-capitalize font-weight-bold">
            Frequently Asked <span className="p_desc">Questions</span>
          </h1>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="my-3 p-4 faq">
              <div className="d-flex align-items-start">
                <div className="mr-3 bg-dark text-white rounded-circle">
                  <svg
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="menu-arrow">
                        <rect
                          width="24"
                          height="24"
                          transform="rotate(180 12 12)"
                          opacity="0"
                        />
                        <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                        <circle cx="12" cy="19" r="1" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="">
                  <a href="#/" className="text-dark stretched-link">
                    <h6
                      className="mb-3"
                      style={{ fontWeight: 600, fontSize: "1.1rem" }}
                    >
                      What could be your first question?
                    </h6>
                  </a>
                  <p className="text-secondary" style={{ lineHeight: 2 }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                    quis assumenda ad deleniti optio dolorum fugiat nobis
                    consequuntur deserunt aut.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="my-3 p-4 faq">
              <div className="d-flex align-items-start">
                <div className="mr-3 bg-dark text-white rounded-circle">
                  <svg
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="menu-arrow">
                        <rect
                          width="24"
                          height="24"
                          transform="rotate(180 12 12)"
                          opacity="0"
                        />
                        <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                        <circle cx="12" cy="19" r="1" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="">
                  <a href="#/" className="text-dark stretched-link">
                    <h6
                      className="mb-3"
                      style={{ fontWeight: 600, fontSize: "1.1rem" }}
                    >
                      What could be your 2nd question?
                    </h6>
                  </a>
                  <p className="text-secondary" style={{ lineHeight: 2 }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                    quis assumenda ad deleniti optio dolorum fugiat nobis
                    consequuntur deserunt aut.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="my-3 p-4 faq">
              <div className="d-flex align-items-start">
                <div className="mr-3 bg-dark text-white rounded-circle">
                  <svg
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="menu-arrow">
                        <rect
                          width="24"
                          height="24"
                          transform="rotate(180 12 12)"
                          opacity="0"
                        />
                        <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                        <circle cx="12" cy="19" r="1" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="">
                  <a href="#/" className="text-dark stretched-link">
                    <h6
                      className="mb-3"
                      style={{ fontWeight: 600, fontSize: "1.1rem" }}
                    >
                      What could be your 3rd question?
                    </h6>
                  </a>
                  <p className="text-secondary" style={{ lineHeight: 2 }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                    quis assumenda ad deleniti optio dolorum fugiat nobis
                    consequuntur deserunt aut.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="my-3 p-4 faq">
              <div className="d-flex align-items-start">
                <div className="mr-3 bg-dark text-white rounded-circle">
                  <svg
                    fill="currentColor"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g data-name="Layer 2">
                      <g data-name="menu-arrow">
                        <rect
                          width="24"
                          height="24"
                          transform="rotate(180 12 12)"
                          opacity="0"
                        />
                        <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                        <circle cx="12" cy="19" r="1" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="">
                  <a href="#/" className="text-dark stretched-link">
                    <h6
                      className="mb-3"
                      style={{ fontWeight: 600, fontSize: "1.1rem" }}
                    >
                      What could be your 4th question?
                    </h6>
                  </a>
                  <p className="text-secondary" style={{ lineHeight: 2 }}>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                    quis assumenda ad deleniti optio dolorum fugiat nobis
                    consequuntur deserunt aut.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
