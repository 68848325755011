import React, { useState, useContext } from "react";
import Profile from "./Profile";
import ExploreMenu from "./ExploreMenu";
//import { lsCheck, fetchNFTDataPerAccount } from "../FetchApis/FetchNFT";
import { Web3Context } from "../Context/Web3context";
//let loaded = false;
import {lsCheck,fetchNFTDataPerAccount} from "../FetchApis/FetchNFT";
import axios from "axios";
import cardImg from "../images/image-placeholder-square.png";


const getData = async (address,menu)=>{
  let items = [];
  let p =[]
  let data;
  let myNFT =[];
  let d = {} 

return fetchNFTDataPerAccount(address,(result)=>{
  //console.log(result);
  
  const _nft = result.data.filter((item) => item.type === 'nft');

  p.push(_nft.map((id)=>{
    
    d.nft = id.contract_address;
    if(id.nft_data!== null)
        p.push(id.nft_data.map((i)=>{

          //console.log(i);
          
          d.creator= [i.owner];
          d.tknId=i.token_id;
          d.name = id.contract_name;
          d.title = id.contract_name;
          d.category='Image';
          d.mime='image/png';
          d.royalty=null;
         
          if(i.token_url.match('https://')||i.token_url.match('ipfs://')){
            if(i.token_url.match('https://cloudflare-ipfs.com/'))
             i.token_url=i.token_url.replace('https://cloudflare-ipfs.com/','https://ipfs.io/ipfs/');
          
           axios.get(i.token_url).then(r=>{
            r.data.image = r.data.image.match('ipfs://')?r.data.image.replace('ipfs://','https://ipfs.io/ipfs/'):r.data.image;
            
            d.creator= [i.owner];
            d.tknId=i.token_id;
            d.name = r.data.name;
            d.title = r.data.name;
            d.image = r.data.image;
            d.category=r.data.mimetype?(r.data.mimetype.match('image')?'Image':'Video'):'Image';
            d.mime=(r.data.mimetype)?r.data.mimetype:'image/png';
            d.royalty=r.data.royalty?r.data.royalty:null;
           
            //console.log(r.data.name);
          });
          
        }
        
       
        }))
        

       
  }));

  /*
  Promise.all(p).then(r=>{
    //console.log(items);
    data = {'items':items,'lastUpdated':Math.floor(new Date().getTime()/1000),addr:address};
    data=JSON.stringify(data);
    //data = explorer;
    //sessionStorage.setItem("myNFT",data);
  //  menu(items);
  })*/
/*
items.push({
          
          image: id.metadata.image,
          title: id.metadata.name,
          nft: id.nftAddress,
          tknId: id.tokenId,
          FT: (id.FT)?id.FT:null,
          category: id.metadata.mimetype?id.metadata.mimetype.match('image')?'Image':'Video':'Image',
          name: id.metadata.name,
          creator: id.metadata.creators?id.metadata.creators:id.owner,
          blockNum:id.blockNum,
          listedPrice: 0,
          seller: id.seller,
          royalty:id.metadata.royalty?id.metadata.royalty:null,
          mime:(id.metadata.mimetype)?id.metadata.mimetype:'image/png',
          editions:id.metadata.editions?id.metadata.editions:'1'
          });
  result.assets.map((id,i=0)=>{
    let type=true;
    //console.log(id,i);
    
    if(id.image_url.match('mp4')||id.image_url.match('mov'))  type=false;
    items.push({  id: i,
      image: id.image_url,
      title: id.asset_contract.name,
      nft: id.asset_contract.address,
      tknId: id.token_id,
      category: id.asset_contract.address,
      name:id.name,
      creator:id.creator.address,
      type: type});
    i++;
  });*/

  })

}
const Nfts = (props) => {
  const state = useContext(Web3Context);
  const [menuItem, setMenuItem] = useState();
  const gMyNFT=async(address)=>{
    let p =[];
    let items=[];
    let nft=[];
    fetchNFTDataPerAccount(address,(result)=>{
      console.log(result);
      
      const _nft = result.filter((item) => item.type === 'nft');
      console.log(_nft);
      _nft.map((id)=>{
       
        //nft.push(id);
        
      
        if(id.nft_data!== null)
            id.nft_data.map((i)=>{
              i.nft = id.contract_address;
              i.name = id.contract_name;
              i.title = id.contract_name;
              nft.push(i);

            })

      });
      
      nft.map(i=>{
        //console.log(r);
        let d={};
        d.nft = i.nft;
        d.name=i.name;
        d.title=i.title;
        d.creator= [i.owner];
        d.tknId=i.token_id;
        d.image = cardImg;
        d.category='Image';
        d.mime='image/png';
        d.royalty=null;
        
        if(i.token_url.match('https://')||i.token_url.match('ipfs://')){
          if(i.token_url.match('https://cloudflare-ipfs.com/'))
           i.token_url=i.token_url.replace('https://cloudflare-ipfs.com/','https://fusible.mypinata.cloud/ipfs/');
           p.push(axios.get(i.token_url).then(r=>{
            r.data.image = r.data.image.match('ipfs://')?r.data.image.replace('ipfs://','https://fusible.mypinata.cloud/ipfs/'):r.data.image;
            d.name = r.data.name;
            d.title = r.data.name;
            d.image = r.data.image;
            d.category=r.data.mimetype?(r.data.mimetype.match('image')?'Image':'Video'):'Image';
            d.mime=(r.data.mimetype)?r.data.mimetype:'image/png';
            d.royalty=r.data.royalty?r.data.royalty:null;
            
            }));
            }
      items.push(d);

      });
      Promise.all(p).then(r=>{
      //data
       console.log(items);
       setMenuItem(items);
      })

    })
  }
  if(state !== undefined && menuItem === undefined){
    /*getData(state.address,r=>{
      console.log(r);
      setMenuItem(r);
    });*/
    gMyNFT(state.address)

  }
  return (
    <>
      <Profile />
      <div className="container" style={{ padding: "0.6rem" }}>
        {menuItem ? (
          <>
            <ExploreMenu menuItem={menuItem} type='2' />
          </>
        ) : (
          <><center>Loading</center></>
        )}
      </div>
    </>
  );
};

export default Nfts;
