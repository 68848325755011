import React from "react";
import AzukiAvatar from "../../images/azuki_img.png";

const Slide1 = () => {
  return (
    <div>
      <div className="imgDiv">
        <img src={AzukiAvatar} alt="AzukiAvatar" />
        <div className="imgDetail">
          <p>AZUKI</p>
          <p>9.00 Ξ</p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          //   width: 250,
          gap: 20,
          marginTop: 20,
        }}
      >
        <button
          className="mintNewBtn"
          onClick={() => console.log("Clicked on above")}
        >
          Above
        </button>
        <button
          onClick={() => console.log("Clicked on below")}
          className="belowBtn"
        >
          Below
        </button>
      </div>
    </div>
  );
};

export default Slide1;
