import React, { useState, useContext } from "react";
import { ReactComponent as HomeIcon } from "../icons/home.svg";
import { ReactComponent as ExploreIcon } from "../icons/compass-with-white-needles.svg";
import logo from "../images/Fusible-logo.png";
import CreateModal from "./CreateModal";
import BtmNav from "./BtmNav";
import AccountIcon from "./AccountIcon";
import { NavLink } from "react-router-dom";
import { Web3Context } from "../Context/Web3context";
//import {NFTSearchData} from "../Context/NFTSearchData";
import Dropdown from "react-bootstrap/Dropdown";
import DarkModeToggle from "./DarkModeToggle";

const Navbar = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const state = useContext(Web3Context);

  return (
    <>
      <div className="nav navbar-light">
        <div className="nav-1">
          <nav className="nav-2 nav-3">
            <div className="head-left head-nav">
              <NavLink exact to="/" className="head-name">
                <img style={{ width: "135px" }} src={logo} alt="" />
              </NavLink>
              <h6>
                <span class="badge badge-primary">beta</span>
              </h6>
            </div>
            {/* <div style={{ overflowY: "visible" }} className="nav-right">
              <div style={{ justifyContent: "center" }} className="home-nav">
                <div style={{ opacity: 0 }} className="nav-h1 nav-sm">
                  <div className="nav-sr">
                    <div className="nav-ser">
                      <div className="nav-sear"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nav-rc" style={{ paddingTop: "10px" }}>
                <div className="help-btn">
                  <DarkModeToggle />
                </div>
              </div>
            </div> */}

            {/* Nav Right Contents 
            <div className="nav-rr"></div>*/}
            <div className="right-cnt">
              {/*  */}
              <div className="nav-item">
                <NavLink
                  activeClassName="hm_active"
                  exact
                  to="/"
                  className="home-btn hm"
                >
                  <HomeIcon
                    style={{
                      width: 16,
                      height: 16,
                      fill: "rgb(39, 117, 219)",
                    }}
                  />
                  <span className="hm-txt">Home</span>
                </NavLink>

                <NavLink
                  activeClassName="hm_active"
                  exact
                  to="/explore"
                  className="home-btn hm-ex"
                >
                  <ExploreIcon
                    style={{
                      width: 16,
                      height: 16,
                      fill: "rgb(39, 117, 219)",
                    }}
                  />
                  <span className="hm-txt">Explore</span>
                </NavLink>
              </div>

              {
                /* Connect Button */
                state ? (
                  <>
                    {/* <div className="cr-btn" onClick={() => setModalShow(true)}> */}
                    {/* <a href="#" className="btn-cr">
                        Create
                      </a> */}
                    <div class="mr-4">
                      <Dropdown>
                        <Dropdown.Toggle
                          style={{
                            backgroundColor: "none",
                            fontWeight: 500,
                            borderRadius: "12px",
                            padding: "6px 12px",
                          }}
                          id="dropdown-basic"
                        >
                          Create
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="drp" style={{ zIndex: 1 }}>
                          <Dropdown.Item
                            onClick={() => setModalShow(true)}
                            href="#/action-1"
                            id="listNFT"
                            className="h-txt"
                          >
                            List NFT
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <NavLink
                              exact
                              to="/mintnft"
                              className="h-txt"
                              style={{ textDecoration: "none" }}
                            >
                              Mint NFT
                            </NavLink>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <CreateModal
                      show={modalShow}
                      init={true}
                      onHide={() => setModalShow(false)}
                    />
                    {/*
                    <div className="rr-btn">
                      <button
                        onClick={(e) => {
                          setToggle(!(toggle));
                        }}
                        className="btn-cc"
                      >
                        {props.web3State.address.substring(0, 5)}
                      </button>
                    </div>
                  */}
                    <AccountIcon state={state} />
                  </>
                ) : (
                  <>
                    <div className="rr-btn">
                      {
                        <button onClick={props.onConnect} className="btn-cc">
                          Connect Wallet
                        </button>
                      }
                    </div>
                  </>
                )
              }
            </div>
          </nav>
        </div>
      </div>

      {/* Mobile view Nav Code */}
      <BtmNav web3State={state} onConnect={props.onConnect} />
    </>
  );
};

export default Navbar;
