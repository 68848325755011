import React from "react";
import { Carousel } from "react-bootstrap";
import Slide1 from "./Slider/Slide1";
import Slide2 from "./Slider/Slide2";
import Slide3 from "./Slider/Slide3";
import Footer from "./Footer";

const Home = () => {
  return (
    <div className="flex homeContainer">
      <div className="leftDiv">
        <h1>
          FRACTIONALIZE <span style={{ color: "#3B9BDF" }}>NFT</span> SPECULATE{" "}
          <span style={{ color: "#FF1D25" }}>FLOOR</span> TRADE, LEND & EARN
        </h1>
      </div>
      <div className="rightDiv">
        <Carousel>
          <Carousel.Item>
            <Slide1 />
          </Carousel.Item>
          <Carousel.Item>
            <Slide2 />
          </Carousel.Item>
          <Carousel.Item>
            <Slide3 />
          </Carousel.Item>
        </Carousel>
      </div>
      <Footer />
    </div>
    
  );
};

export default Home;
